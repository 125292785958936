import { message } from 'antd';
import {
  useLazyGetInvoicesSavedQuery,
  useSubmitBulkInvoicesMutation,
  useSubmitInvoiceMutation,
} from 'features/invoices/api';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'shared/components/button';
import { useInvoiceTableConfig } from './hook/useInvoiceTableConfig';
import { InvoicesTableData, TableData } from 'shared/types/invoices';
import { formatDateToString } from 'shared/libs/helpers/helperFunctions/helperFunctions';
import { PlusOutlined } from '@ant-design/icons';
import { InvoicesTable } from 'widgets/InvoicesTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import './styles/invoices-saved.scss';
import {
  InvoiceData,
  InvoiceResponse,
} from 'features/invoices/types/invoicesTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FiltersForm } from 'widgets/FiltersForm';
import { useSort } from './hook/useSort';
import { useFilter } from './hook/useFilter';
import { ModalError } from 'shared/components/modalError';
import dayjs, { Dayjs } from 'dayjs';
import { getDateRange } from 'shared/libs/helpers/helperFunctions/dateRangeParser';
import { formatValueToСurrency } from 'shared/libs/helpers/helperFunctions/helperFunctions';
import {
  DateRange,
  IModalConfirmContent,
  IModalErrorContent,
} from 'shared/types/shared';
import { ModalConfirm } from 'shared/components/modalConfirm';

interface IErrorBulkSubmit {
  detail: string;
  errors: {
    company: string;
    id: number;
    msg: string;
  }[][];
}

const Invoices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [savedInvoice, setSavedInvoices] = useState<InvoiceResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [fetchSavedInvoices] = useLazyGetInvoicesSavedQuery();
  const [fetchSubmitInvoice] = useSubmitInvoiceMutation();
  const [fetchBulkSubmitInvoices] = useSubmitBulkInvoicesMutation();
  const [selectedBulkInvoices, setSelectedBulkInvoices] = useState<React.Key[]>(
    []
  );
  const [disabledCompaniesForBulk, setDisabledCompaniesForBulk] = useState<
    InvoiceData[]
  >([]);

  const [disabledCompaniesCountForBulk, setDisabledCompaniesCountForBulk] =
    useState<number | null>(null);

  const [modalErrorContent, setModalErrorContent] =
    useState<IModalErrorContent>({
      title: '',
      content: null,
    });
  const [modalConfirmContent, setModalConfirmContent] =
    useState<IModalConfirmContent>({
      title: '',
      content: null,
      onCancel: () => null,
      onOk: () => null,
    });

  const [emptyTableText, setEmptyTableText] = useState(
    'No saved invoices. Create an Invoice.'
  );

  const { handleSort, sortKey, sortOrder } = useSort();

  const {
    handleFiltersApply,
    handleClearAll,
    dateCreatedFilter,
    companiesFilter,
  } = useFilter({ setCurrentPage });

  const fetchInvoices = async (page: number, pageSize: number) => {
    try {
      let dateCreatedFrom: Dayjs | null = null;
      let dateCreatedTo: Dayjs | null = null;

      const searchParams = new URLSearchParams(location.search);
      const sortParam = searchParams.get('sort');
      const companiesParam = searchParams.get('companies');
      const dateCreatedParam = searchParams.get('date_created');
      if (dateCreatedParam !== null && dateCreatedParam !== 'full_period') {
        const { from, to } = getDateRange(dateCreatedParam as DateRange);
        dateCreatedFrom = from;
        dateCreatedTo = to;
      }
      const searchUrl = `${sortParam ? `&ordering=${sortParam}` : ''}${companiesParam ? `&company=${companiesParam}` : ''}${dateCreatedFrom && dateCreatedTo ? `&created_after=${dayjs(dateCreatedFrom).format('YYYY-MM-DD')}&created_before=${dayjs(dateCreatedTo).format('YYYY-MM-DD')}` : ''}`;
      setIsLoading(true);
      const { data, isError } = await fetchSavedInvoices({
        status: 'draft',
        pageNumber: page,
        pageSize: pageSize,
        searchParams: searchUrl,
      });

      if (isError) {
        throw new Error('Failed to fetch saved invoices');
      }
      if (!data?.results.length) {
        setSavedInvoices(null);
      }
      if (!data?.results.length && searchUrl) {
        setEmptyTableText('Nothing was found');
      }
      data?.results.length && setSavedInvoices(data);
    } catch (e) {
      message.error('Failed to fetch saved invoices');
      console.error(e, 'fetch SAVED invoices');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setSelectedBulkInvoices([]);
    fetchInvoices(currentPage, pageSize);
  }, [currentPage, pageSize, location.search]);

  const handleSubmitInvoice = async (invoiceId: number) => {
    try {
      setIsSubmittingInvoice(true);
      const response = await fetchSubmitInvoice(invoiceId);
      if ('error' in response) {
        const error = response.error as FetchBaseQueryError;
        if (
          error.data &&
          typeof error.data === 'object' &&
          'error' in error.data
        ) {
          throw new Error(
            (error.data as { error: string }).error ||
              'Failed to submit invoice'
          );
        } else {
          throw new Error('Failed to submit invoice');
        }
      }
      setCurrentPage(1);

      await fetchInvoices(1, pageSize);
      message.success('Invoice submitted successfully');
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'Failed to submit Invoice';
      if (errorMessage.startsWith('Invoices for')) {
        setModalErrorContent({
          content: errorMessage,
          title: 'Submitting error',
        });
      } else {
        message.error(errorMessage);
      }
    } finally {
      setIsSubmittingInvoice(false);
      handleCloseConfirmModal();
      handleCloseModalError();
    }
  };

  const handleBulkSubmit = async () => {
    const errorMessage = 'Bulk submission failed';
    if (!selectedBulkInvoices.length) return;
    setIsSubmittingInvoice(true);
    try {
      const response = await fetchBulkSubmitInvoices({
        ids: selectedBulkInvoices as number[],
      });

      if ('error' in response) {
        const error = response.error as FetchBaseQueryError;

        if (
          error.data &&
          typeof error.data === 'object' &&
          'errors' in error.data
        ) {
          const bulkErrors = (error.data as IErrorBulkSubmit).errors[0];

          if (bulkErrors.length !== selectedBulkInvoices.length) {
            const submittedInvoicesLength =
              selectedBulkInvoices.length - bulkErrors.length;
            submittedInvoicesLength &&
              submittedInvoicesLength > 0 &&
              message.success(
                `${submittedInvoicesLength} ${submittedInvoicesLength > 1 ? 'Invoices' : 'Invoice'} submitted successfully`
              );
          }
          const notSubmittedBulkInvoices = selectedBulkInvoices.filter((item) =>
            bulkErrors.find((error) => error.id === item)
          );

          setSelectedBulkInvoices(notSubmittedBulkInvoices);
          if (bulkErrors.length === selectedBulkInvoices.length) {
            throw Error(errorMessage);
          }
        } else {
          throw Error(errorMessage);
        }
      } else {
        message.success(
          `${selectedBulkInvoices.length} ${selectedBulkInvoices.length > 1 ? 'Invoices' : 'Invoice'} submitted successfully`
        );
        setSelectedBulkInvoices([]);
      }

      setCurrentPage(1);
      await fetchInvoices(1, pageSize);
    } catch (error) {
      if (error instanceof Error && error.message === errorMessage) {
        message.error(errorMessage);
      }
      console.error(error, 'Bulk submission error');
    } finally {
      setIsSubmittingInvoice(false);
      handleCloseConfirmModal();
      handleCloseModalError();
    }
  };

  const handleCloseModalError = () => {
    setModalErrorContent({
      content: null,
      title: '',
    });
  };

  const handleCloseConfirmModal = () => {
    setModalConfirmContent({
      content: null,
      title: '',
      onCancel: () => null,
      onOk: () => null,
    });
  };

  const handleBulkPreSubmit = () => {
    if (selectedBulkInvoices.length === disabledCompaniesForBulk.length) {
      setModalErrorContent({
        title: '',
        content: (
          <div>
            <p className="normal-16">{`Selected ${selectedBulkInvoices.length > 1 ? 'invoices' : 'invoice'} couldn't be sent.`}</p>
            <p className="normal-16">Try again later.</p>
          </div>
        ),
      });
      return;
    }
    if (selectedBulkInvoices.length === disabledCompaniesCountForBulk) {
      setModalErrorContent({
        title: '',
        content: (
          <div>
            <p className="normal-16">{`Selected ${selectedBulkInvoices.length > 1 ? 'invoices' : 'invoice'} couldn't be sent.`}</p>
            <p className="normal-16">Fill empty invoice fields at first.</p>
          </div>
        ),
      });
      return;
    }
    setModalConfirmContent({
      title: '',
      content: (
        <div>
          <p className="normal-16">{`Sending ${selectedBulkInvoices.length - (disabledCompaniesCountForBulk || 0)}/${selectedBulkInvoices.length} ${selectedBulkInvoices.length > 1 ? 'invoices' : 'invoice'} for submitting. `}</p>
          <p>Submitting couldn't be undone.</p>
          <p>Continue?</p>
        </div>
      ),
      onCancel: handleCloseConfirmModal,
      onOk: handleBulkSubmit,
    });
  };

  const handleViewInvoice = (record: TableData) => {
    handleRowClick(record);
  };
  const handleEditInvoice = (record: TableData) => {
    const recordId = record.id;
    navigate(
      RoutePath['/invoice/edit/:id'].replace(':id', recordId.toString()),
      {
        state: { from: location.pathname },
      }
    );
  };
  const submitInvoice = async (record: TableData) => {
    const currentInvoice = savedInvoice?.results.find(
      (item) => item.id === record.id
    );
    if (currentInvoice?.company_status === 'disabled') {
      setModalErrorContent({
        title: 'Error submit invoice',
        content: (
          <div>
            <p className="normal-16">{`Invoices for ${currentInvoice.company} couldn't be submitted temporary. Try again later.`}</p>
          </div>
        ),
      });
      return;
    }
    if (currentInvoice && !currentInvoice?.match_config) {
      setModalErrorContent({
        title: 'Error submit invoice',
        content: (
          <div>
            <p className="normal-16">Selected invoice couldn't be sent.</p>
            <p className="normal-16">Fill empty invoice fields at first.</p>
          </div>
        ),
      });

      return;
    }
    setModalConfirmContent({
      title: '',
      content: (
        <div>
          <p>You are about to submit your invoice for processing.</p>
          <p>Would you like to continue?</p>
        </div>
      ),
      onCancel: handleCloseConfirmModal,
      onOk: () => handleSubmitInvoice(record.id),
    });
  };

  const handleRowClick = (record: TableData) => {
    const recordId = record.id;
    navigate(
      `${RoutePath['/invoice/:id'].replace(':id', recordId.toString())}`
    );
  };

  const handleAddInvoice = () => {
    navigate(RoutePath['/invoice/create']);
  };

  const { tableColumns } = useInvoiceTableConfig({
    handleViewInvoice,
    handleEditInvoice,
    submitInvoice,
    handleRowClick,
    handleSort,
    sortOrder,
    sortKey,
  });

  const dataToRender = useMemo((): InvoicesTableData[] => {
    if (!savedInvoice?.results.length) {
      return [];
    }
    return savedInvoice.results.map((invoice, idx) => ({
      company: invoice.company,
      created: formatDateToString(invoice.created),
      id: invoice.id,
      receipt_number: invoice.receipt_number || '',
      total_amount: formatValueToСurrency(invoice.total_amount || ''),
      isShowWarning: !invoice.match_config,
      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
    }));
  }, [savedInvoice]);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (selectedBulkInvoices.length && savedInvoice?.results.length) {
      const invoicesWithCompany = savedInvoice.results.filter((item) =>
        selectedBulkInvoices.find((bulk) => bulk === item.id)
      );
      const disabledCompanies = invoicesWithCompany.filter(
        (invoice) => invoice.company_status === 'disabled'
      );
      const notMatchConfigCompanies = invoicesWithCompany.filter(
        (invoice) => !invoice.match_config
      );
      const disabledCompanyOrEmptyFields = [
        ...disabledCompanies,
        ...notMatchConfigCompanies,
      ];
      setDisabledCompaniesForBulk(disabledCompanies);
      setDisabledCompaniesCountForBulk(disabledCompanyOrEmptyFields.length);
    }
  }, [selectedBulkInvoices]);
  return (
    <div className="invoices-table">
      {modalErrorContent.content && (
        <ModalError onOk={handleCloseModalError} {...modalErrorContent} />
      )}
      {modalConfirmContent.content && <ModalConfirm {...modalConfirmContent} />}
      <div className="invoice-filter-block">
        <FiltersForm
          dataCreatedInit={dateCreatedFilter}
          handleFiltersApply={handleFiltersApply}
          handleClearAll={handleClearAll}
          companiesInit={companiesFilter}
          filters={['companies', 'dateCreated']}
        />
      </div>
      {!!selectedBulkInvoices.length && (
        <Button
          className="bulk-submit-top"
          onClick={handleBulkPreSubmit}
          disabled={isSubmittingInvoice}
        >
          Submit All
        </Button>
      )}

      <InvoicesTable
        tableColumns={tableColumns}
        dataToRender={dataToRender}
        emptyTextRender={emptyTableText}
        invoicesType="draft"
        totalInvoices={savedInvoice?.count || 0}
        onPageChange={handlePageChange}
        isLoadingTable={isLoading || isSubmittingInvoice}
        pageSize={pageSize}
        setSelectedRowKeys={setSelectedBulkInvoices}
        selectedRowKeys={selectedBulkInvoices}
        currentPage={currentPage}
      />

      {!!selectedBulkInvoices.length && (
        <Button
          className="bulk-submit-btm"
          disabled={isSubmittingInvoice}
          onClick={handleBulkPreSubmit}
        >
          Submit All
        </Button>
      )}
      <Button className="btn-invoices-add" onClick={handleAddInvoice}>
        <PlusOutlined />
      </Button>
    </div>
  );
};

export default Invoices;

import React from 'react';
import { Logo } from 'shared/assets/icons';
import { RegisterForm } from 'widgets/RegisterForm';

const Register = () => {
  return (
    <div className="pageContent center-page">
      <div className="logo-register">
        <Logo />
      </div>
      <h2 className="header-text">Registration</h2>
      <RegisterForm />
    </div>
  );
};

export default Register;

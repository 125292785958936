import { message } from 'antd';
import { useChangePasswordMutation } from 'features/profile/api';
import { IChangePassword } from 'features/profile/types/profileTypes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { errorNewPassword } from 'shared/constants';
import ChangePasswordForm from 'widgets/ChangePasswordFrom/ui/ChangePasswordForm';

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [changePassword] = useChangePasswordMutation();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(RoutePath['/profile']);
  };

  const handleChangePassword = async (data: IChangePassword) => {
    setIsLoading(true);
    setError('');
    try {
      const result = await changePassword(data);
      if (result.error) {
        throw result.error;
      }
      navigate(RoutePath['/change-password-successfully']);
    } catch (e) {
      const error = e as { data?: { new_password?: string[] } };
      console.error(e, 'failed change password');
      message.error('fetch change password failed');
      if (
        error?.data &&
        error.data.new_password &&
        error.data.new_password[0] === errorNewPassword
      ) {
        return setError(errorNewPassword);
      }
      setError('Invalid Password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pageContent center-page">
      <h2 className="header-text">Change Password</h2>
      <ChangePasswordForm
        handleBack={handleBack}
        isLoading={isLoading}
        handleChangePassword={handleChangePassword}
        error={error}
      />
    </div>
  );
};

export default ChangePassword;

import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../styles/stages.scss';
import { TypeStage } from '../types/stages';

export function Stages({
  stepper = false,
  activeStage,
  ...props
}: TypeStage): React.ReactElement<TypeStage> {
  return (
    <div className="stage">
      <TransitionGroup>
        <CSSTransition
          key={activeStage}
          timeout={500}
          classNames="fade"
          unmountOnExit
        >
          <div>{props.stages[activeStage]}</div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

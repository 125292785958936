import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { IResetPasswordFormForm } from './types/resetPasswordFormTypes';
import { CustomLabel } from 'shared/components/customLabel';
import { Button } from 'shared/components/button';
import { Loading } from 'shared/components/loading';
import { CustomLink } from 'shared/components/customLink';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { useResetPasswordMutation } from 'features/auth/api';
import { useNavigate } from 'react-router-dom';
import { setLocalStorage } from 'shared/libs/storageService/localStorage';
import { USER_EMAIL } from 'shared/constants';

export const ResetPasswordForm = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm<IResetPasswordFormForm>();
  const [fetchResetPassword, { isLoading }] = useResetPasswordMutation();

  const handleSubmit = async (value: IResetPasswordFormForm) => {
    try {
      await fetchResetPassword(value.email).unwrap();
      setLocalStorage(USER_EMAIL, value.email);
      navigate(RoutePath['/reset-password-verify']);
    } catch (e) {
      form.setFields([
        {
          name: 'email',
          errors: ['Invalid email'],
        },
      ]);
    }
  };

  const handleFormValuesChange = () => {
    const values = form.getFieldsValue();
    const allFieldsFilled = Object.values(values).every(
      (value) => value && value.length >= 8
    );

    setIsButtonDisabled(!allFieldsFilled);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ maxWidth: '400px', width: '100%' }}
      initialValues={{}}
      onFinish={handleSubmit}
      onValuesChange={handleFormValuesChange}
    >
      <Form.Item
        label={<CustomLabel text="Email" />}
        name="email"
        rules={[
          { required: true, message: 'Please input your email!' },
          { type: 'email', message: 'Not an email' },
        ]}
        validateTrigger="onSubmit"
      >
        <Input type="text" className="input" size="large" />
      </Form.Item>

      <div className="buttons-wrapper mt-16">
        <Button
          className="flex-row mb-24"
          type="submit"
          disabled={isLoading || isButtonDisabled}
        >
          Reset Password
          {isLoading && <Loading className="ml-12 spin-white" size="small" />}
        </Button>

        <CustomLink to={RoutePath['/login']}>Login</CustomLink>
      </div>
    </Form>
  );
};

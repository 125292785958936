import { message } from 'antd';
import {
  useLazyGetCompanyConfigQuery,
  useLazyGetInvoiceByIdQuery,
  useUpdateInvoiceMutation,
} from 'features/invoices/api';
import {
  ICompanyConfigResponse,
  IInvoiceById,
} from 'features/invoices/types/invoicesTypes';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { InvoiceCreateForm } from 'widgets/InvoiceCreateForm';
import ProfileInfo from 'widgets/ProfileInfo/ui/ProfileInfo';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useGetProfileCodesQuery } from 'features/profile/api';
import { IProfile } from 'features/profile/types/profileTypes';
import { transformAccountResponse } from 'shared/libs/transformers/profile';

const EditInvoice = () => {
  const [invoice, setInvoice] = useState<IInvoiceById | null>(null);
  const [profileData, setProfileData] = useState<IProfile | null>(null);
  const [companyConfig, setCompanyConfig] =
    useState<ICompanyConfigResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { data: profileCodes } = useGetProfileCodesQuery({});

  const navigate = useNavigate();
  const location = useLocation();

  const [getInvoiceById] = useLazyGetInvoiceByIdQuery();
  const [fetchCompanyConfig] = useLazyGetCompanyConfigQuery();

  const [updateInvoice] = useUpdateInvoiceMutation();

  const { id } = useParams<{ id: string }>();

  const getCompanyConfig = async (companyId: number) => {
    try {
      const data = await fetchCompanyConfig(companyId).unwrap();
      setCompanyConfig(data);
    } catch (e) {
      message.error('Failed fetch company');
      console.error(e, 'Edit invoice get config failed');
    }
  };

  const fetchInvoiceById = async () => {
    if (!id) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const data = await getInvoiceById(id);
      if (data.error) {
        throw data.error;
      }
      if (!data.data) return;
      setProfileData(transformAccountResponse(data.data.profile));
      setInvoice(data.data);
      data.data.company_id && (await getCompanyConfig(data.data.company_id));
    } catch (e) {
      message.error('Fetch invoice failed');
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToSavedInvoices = () => {
    navigate(RoutePath['/invoices/saved']);
  };

  const handleBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(RoutePath['/invoices/saved']);
    }
  };

  const handleUpdateInvoice = async (data: FormData) => {
    if (!invoice?.id) return;
    try {
      await updateInvoice({
        invoiceId: invoice?.id,
        data: data,
      }).unwrap();
      message.success('Invoice created successfully!');
      navigate(RoutePath['/invoices/saved']);
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    fetchInvoiceById();
  }, [id]);

  const groupedTaxRegimeOptions = {
    personal:
      profileCodes?.tax_regime.personal?.map((item) => ({
        value: item.code,
        label: `${item.code}: ${item.description}`,
      })) || [],
    company:
      profileCodes?.tax_regime.company?.map((item) => ({
        value: item.code,
        label: `${item.code}: ${item.description}`,
      })) || [],
  };

  const groupedCfdiOptions = {
    personal:
      profileCodes?.cfdi.personal?.map((item) => ({
        value: item.code,
        label: `${item.code}: ${item.description}`,
      })) || [],
    company:
      profileCodes?.cfdi.company?.map((item) => ({
        value: item.code,
        label: `${item.code}: ${item.description}`,
      })) || [],
  };
  const initialCfdiValue =
    invoice?.actions.find((item) => item.field_name.includes('cfdi'))?.value ||
    '';
  const initialTaxRegimeValue =
    invoice?.actions.find((item) => item.field_name.includes('tax_regime'))
      ?.value || '';
  return (
    <div>
      <ArrowLeftOutlined className="arrow-back" onClick={handleBack} />
      {profileData?.name && <ProfileInfo profile={profileData}></ProfileInfo>}
      <hr></hr>
      <InvoiceCreateForm
        companyConfig={companyConfig}
        isLoadingConfig={isLoading}
        invoice={invoice}
        navigateToSavedInvoices={navigateToSavedInvoices}
        handleCreateInvoice={handleUpdateInvoice}
        groupedTaxRegimeOptions={groupedTaxRegimeOptions}
        groupedCfdiOptions={groupedCfdiOptions}
        initialCfdiValue={initialCfdiValue}
        initialTaxRegimeValue={initialTaxRegimeValue}
      />
    </div>
  );
};

export default EditInvoice;

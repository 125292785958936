import { Avatar, Dropdown, MenuProps } from 'antd';
import React from 'react';
import { CustomLink } from 'shared/components/customLink';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import '../styles/navigate.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Logo } from 'shared/assets/icons';
import { useLogoutMutation } from 'features/auth/api';
import { getCookie, removeCookie } from 'shared/libs/storageService/cookie';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'shared/constants';
import { profileSlice } from 'features/profile/api/profileSlice';
import { useAppDispatch, useAppSelector } from 'app/providers/store';

type MenuItem = {
  key: string;
  path: string;
  text: string;
  isActive: boolean;
};

const items: MenuItem[] = [
  {
    key: 'invoices',
    path: RoutePath['/invoices/saved'],
    text: 'Saved Invoices',
    isActive: true,
  },

  {
    key: 'invoices_submitted',
    path: RoutePath['/invoices/submitted'],
    text: 'Submitted Invoices',
    isActive: false,
  },
];

const Navigate = () => {
  const location = useLocation();
  const [fetchLogout, { isLoading }] = useLogoutMutation();
  const dispatch = useAppDispatch();
  const { setProfile } = profileSlice.actions;
  const navigate = useNavigate();
  const { profile } = useAppSelector((state) => state.profileReducer);

  const isActive = (paths: string[]) => {
    return paths.some((path) => location.pathname === path);
  };

  const handleLogout = async () => {
    const refreshToken = getCookie(REFRESH_TOKEN);
    try {
      await fetchLogout({ refresh_token: refreshToken }).unwrap();
    } catch (e) {
      console.error(e, 'Logout');
    } finally {
      removeCookie(REFRESH_TOKEN);
      removeCookie(ACCESS_TOKEN);
      dispatch(setProfile(null));
      navigate(RoutePath['/login']);
    }
  };

  const handleMenuClick = (key: 'logout' | 'profile') => {
    switch (key) {
      case 'logout': {
        handleLogout();
        break;
      }
      case 'profile': {
        navigate(RoutePath['/profile'], {
          state: { from: location.pathname },
        });
        break;
      }
    }
  };

  const menu: MenuProps = {
    items: [
      {
        key: 'profile',
        title: 'Profile',
        label: (
          <CustomLink
            className={`navigate-dropdown-list-item`}
            to={RoutePath['/profile']}
            state={{ from: location.pathname }}
          >
            <UserOutlined /> Personal Data
          </CustomLink>
          // <div
          //   className="navigate-dropdown-list-item"
          //   onClick={() => handleMenuClick('profile')}
          // >
          //
          // </div>
        ),
        disabled: !profile?.user.id,
      },
      {
        key: 'logout',
        title: 'Logout',
        label: (
          <div
            className="navigate-dropdown-list-item navigate-dropdown-list-item-logout"
            onClick={() => handleMenuClick('logout')}
          >
            {' '}
            <LogoutOutlined /> Logout
          </div>
        ),
        disabled: isLoading,
      },
    ].filter((item) => (!profile?.user.id ? item.key === 'logout' : item)),
  };

  return (
    <div className="navbar-container">
      <div className="navbar-content">
        <Link className="logo-app" to={RoutePath['/invoices/saved']}>
          <Logo />
        </Link>
        <div className="navbar-list">
          {items.map((item) => (
            <React.Fragment key={item.key}>
              <CustomLink
                className={`${isActive([item.path]) ? 'active' : ''}`}
                to={item.path}
              >
                {item.text}
              </CustomLink>
            </React.Fragment>
          ))}
        </div>
        <div>
          <Dropdown
            menu={menu}
            trigger={['click']}
            overlayClassName="navigate-dropdown-list"
          >
            <Avatar
              size={42}
              icon={<UserOutlined />}
              style={{ cursor: 'pointer' }}
              className="avatar-icon"
            />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Navigate;

import { baseQueryWithReauth } from '../../../shared/libs/tokenService/tokenService';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ICompaniesResponse,
  ICompanyConfigResponse,
  IGetInvoiceRequest,
  IInvoiceById,
  InvoiceResponse,
  ISaveInvoice,
  ISubmitBulkInvoices,
  IUpdateInvoiceRequest,
} from '../types/invoicesTypes';

export const invoiceApi = createApi({
  reducerPath: 'invoiceApi',
  tagTypes: ['SavedInvoice'],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getInvoicesSaved: build.query<InvoiceResponse, IGetInvoiceRequest>({
      query: ({ status, pageNumber, pageSize, searchParams }) => {
        const searchParamsUrl = `?status=${status}&page=${pageNumber || 1}&page_size=${pageSize || 10}${searchParams ? searchParams : ''}`;
        return {
          url: `/invoices/${searchParamsUrl}`,
          method: 'GET',
        };
      },
      providesTags: (result, error, status) => [
        { type: 'SavedInvoice', status },
      ],
    }),
    getCompanies: build.query<ICompaniesResponse, unknown>({
      query: () => ({
        url: '/companies/',
        method: 'GET',
      }),
    }),
    getCompanyConfig: build.query<ICompanyConfigResponse, number>({
      query: (companyId) => ({
        url: `/company/${companyId}`,
        method: 'GET',
      }),
    }),
    saveInvoice: build.mutation<unknown, ISaveInvoice>({
      query: (data) => ({
        url: '/invoices/save/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => [
        { type: 'SavedInvoice', status: 'draft' },
      ],
    }),
    submitInvoice: build.mutation<unknown, number>({
      query: (invoiceId) => ({
        url: `/invoices/${invoiceId}/submit/`,
        method: 'POST',
      }),
    }),
    submitBulkInvoices: build.mutation<unknown, ISubmitBulkInvoices>({
      query: (invoicesIds) => ({
        url: `/invoices/bulk_submit/`,
        method: 'POST',
        body: invoicesIds,
      }),
    }),

    getInvoiceById: build.query<IInvoiceById, string>({
      query: (invoiceId) => ({
        url: `/invoices/${invoiceId}/`,
        method: 'GET',
      }),
    }),
    updateInvoice: build.mutation<unknown, IUpdateInvoiceRequest>({
      query: ({ invoiceId, data }) => {
        return {
          url: `/invoices/${invoiceId}/`,
          method: 'PATCH',
          body: { data: data },
        };
      },
    }),
  }),
});

export const dateRangeOptions = [
  {
    value: 'full_period',
    label: 'Full period',
  },
  {
    value: 'last_day',
    label: 'Yesterday',
  },
  {
    value: 'last_week',
    label: 'Last week',
  },
  {
    value: 'last_month',
    label: 'Last month',
  },
  {
    value: 'last_quarter',
    label: 'Last quarter',
  },
  {
    value: 'last_half_a_year',
    label: 'Last half a year',
  },
  {
    value: 'last_year',
    label: 'Last year',
  },
];

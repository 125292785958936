import React from 'react';
import '../styles/footer.scss';
import { Logo } from 'shared/assets/icons';
import { CustomLink } from 'shared/components/customLink';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-item">
        <div className="footer-logo">
          <Logo />
        </div>
        <div className="footer-text">
          <p>
            Our mission at Factura Mas Rapido centers around the development of
            cutting-edge digital platforms and apps, specifically designed to
            revolutionise the factura submission process within the Mexican
            market. We achieve this fete by embracing Artificial Intelligence
            and automation. We not only streamline the process, but elevate the
            efficiency of this essential procedure.
            <br />
            <br />
          </p>
          <p>
            Users stand to benefit significantly, as the implementation of our
            platform and app could on average provide up to a{' '}
            <strong>2,200%</strong> quicker time-saving process and
            exceptionally smooth experience.
            <br />
            <br />
          </p>
          <p>
            This innovation is not just about simplifying tasks; it's about
            transforming how self-employed, contractor and business users
            interact with the e-factura landscape in Mexico; bringing a new
            level of efficiency and ease to the forefront.
          </p>
        </div>
        <div className="footer-links">
          <CustomLink to={RoutePath['/about-us']}>About Us</CustomLink>
          <CustomLink to={RoutePath['/why-use-factura-mas-rapido']}>
            Why Use Factura Mas Rapido?
          </CustomLink>
          <CustomLink to={RoutePath['/contact-us']}>Contact Us</CustomLink>
        </div>
      </div>
    </footer>
  );
};

import { IProfilesCode } from 'features/profile/types/profileTypes';

export const profileType = {
  personalUser: 'personal',
  companyUser: 'company',
};

export const getTaxRegimPersonalCodes = (
  codes: IProfilesCode['tax_regime']['personal']
) => {
  return codes.map((code) => ({
    value: code.id,
    label: `${code.code}: ${code.description}`,
  }));
};

export const getTaxRegimCompanyCodes = (
  codes: IProfilesCode['tax_regime']['company']
) => {
  return codes.map((code) => ({
    value: code.id,
    label: `${code.code}: ${code.description}`,
  }));
};

export const getCfdiPersonalOptions = (
  codes: IProfilesCode['cfdi']['personal']
) => {
  return codes.map((code) => ({
    value: code.id,
    label: `${code.code}: ${code.description}`,
  }));
};
export const getCfdiCompanyOptions = (
  codes: IProfilesCode['cfdi']['company']
) => {
  return codes.map((code) => ({
    value: code.id,
    label: `${code.code}: ${code.description}`,
  }));
};

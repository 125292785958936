import React from 'react';
import { Logo } from 'shared/assets/icons';
import { LoginForm } from 'widgets/LoginForm';

const Login = () => {
  return (
    <div className="pageContent center-page">
      <div className="logo-register">
        <Logo />
      </div>

      <h2 className="header-text">Login</h2>
      <LoginForm />
    </div>
  );
};

export default Login;

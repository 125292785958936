import React from 'react';

import { ResetPasswordSuccessfullyForm } from 'widgets/ResetPasswordSuccessfullyForm';
import { Logo } from 'shared/assets/icons';

const ResetPasswordSuccessfully = () => {
  return (
    <div className="pageContent center-page">
      <ResetPasswordSuccessfullyForm />
    </div>
  );
};

export default ResetPasswordSuccessfully;

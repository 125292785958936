import React from 'react';
import addInvoiceImg from '../../../shared/assets/images/add_invoice.png';
import selectCompanyImg from '../../../shared/assets/images/select_company.png';
import submitAllImg from '../../../shared/assets/images/submit_all.png';

import { Button } from 'shared/components/button';
import { ISteps } from '../types/onboardingTypes';
import { HandIcon } from 'shared/assets/icons';
export const Step2: React.FC<ISteps> = ({ setNextStep }) => {
  return (
    <div className="onboarding-step">
      <div className="img img-add-invoice">
        <img src={addInvoiceImg} alt="add_invoice-img" />
        <div className="description description-add-invoice">
          <HandIcon className="icon-hand" />
          Сlick a button to add and save a new invoice
        </div>
      </div>
      <div className="img img-select-company">
        <img src={selectCompanyImg} alt="select-company-img" />
        <div className="description description-select-company">
          <HandIcon className="icon-hand" />
          Select your company and fill out the invoice info in a few clicks
        </div>
      </div>
      <div className="img img-submit-all">
        <img src={submitAllImg} alt="submit-all-img" />
        <div className="description description-submit-all">
          <HandIcon className="icon-hand" />
          Send saved invoices to vendor in one click
        </div>
      </div>
    </div>
  );
};

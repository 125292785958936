import moment from 'moment';
import { domainNameRegExp, protocolNameRegExp } from '../../../regExpText';
import { CfdiItem } from 'features/profile/types/profileTypes';

export function getDomainName(url: string) {
  const domain = url.match(domainNameRegExp);
  if (!domain) return '';
  return domain[0].replace(protocolNameRegExp, '');
}

export const formatDateToString = (date: Date): string => {
  return moment(date).format('MM-DD-YYYY');
};

export const parseProfileCodes = ({
  value,
  codes,
}: {
  value: string;
  codes: CfdiItem[];
}): string => {
  const currentValue = codes.find((item) => item.code === value);
  if (currentValue) {
    return `${currentValue.code}: ${currentValue.description}`;
  }
  return value;
};

export const formatValueToСurrency = (value: string): string => {
  if (!value || /[a-zA-Z]/.test(value)) return '';
  const numericValue = value.replace(/[^\d.]/g, '');

  const [integerPart, decimalPart] = numericValue.split('.');

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );

  const formattedDecimalPart =
    decimalPart === undefined || decimalPart === ''
      ? '00'
      : decimalPart.padEnd(2, '0');

  return `${formattedIntegerPart}.${formattedDecimalPart}`;
};

import React, { useEffect, useState } from 'react';
import { SortedKey } from '../types/sortTypes';
import { useLocation, useNavigate } from 'react-router-dom';

export const useSort = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [sortKey, setSortKey] = useState<SortedKey>('created');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    const sortParam = currentParams.get('sort');
    if (sortParam) {
      const newSortKey = sortParam.replace(/^-/, '');
      const newSortOrder = sortParam.startsWith('-') ? 'desc' : 'asc';
      setSortKey(newSortKey as SortedKey);
      setSortOrder(newSortOrder);
    }
  }, []);

  const updateSortParams = (key: SortedKey, order: 'asc' | 'desc') => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('sort', order === 'desc' ? `-${key}` : key);
    navigate({ search: searchParams.toString() });
  };

  const handleSort = (key: SortedKey, order: 'asc' | 'desc') => {
    if (key === sortKey && order === sortOrder) {
      return;
    }
    setSortKey(key);
    setSortOrder(order);
    updateSortParams(key, order);
  };
  return {
    handleSort,
    sortKey,
    sortOrder,
  };
};

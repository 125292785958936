import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  LoginRequest,
  LogoutUser,
  RegisterUser,
  SetNewPassword,
  VerifyEmailRequest,
  VerifyEmailResponse,
  VerifyTokenRequest,
} from '../types/authTypes';
import { getCookie } from 'shared/libs/storageService/cookie';
import { ACCESS_TOKEN } from 'shared/constants';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_PATH,
  }),
  endpoints: (build) => ({
    registerUser: build.mutation<RegisterUser, RegisterUser>({
      query: (user) => ({
        url: '/register-user/',
        method: 'POST',
        body: user,
      }),
    }),
    resendEmailVerification: build.mutation<
      RegisterUser['email'],
      RegisterUser['email']
    >({
      query: (email) => ({
        url: '/resend-email-verification-token/',
        method: 'POST',
        body: { email: email },
      }),
    }),
    verifyEmail: build.mutation<VerifyEmailResponse, VerifyEmailRequest>({
      query: (token) => ({
        url: '/verify-email/',
        method: 'POST',
        body: token,
      }),
    }),
    verifyToken: build.mutation<VerifyTokenRequest, VerifyTokenRequest>({
      query: (token) => ({
        url: '/verify-token/',
        method: 'POST',
        body: token,
      }),
    }),

    login: build.mutation<VerifyEmailResponse, LoginRequest>({
      query: (user) => ({
        url: '/obtain-token-pair/',
        method: 'POST',
        body: user,
      }),
    }),
    logout: build.mutation<LogoutUser, LogoutUser>({
      query: (refreshToken) => {
        const token = getCookie(ACCESS_TOKEN);

        return {
          url: '/revoke-refresh-token/',
          method: 'POST',
          body: refreshToken,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    resetPassword: build.mutation<RegisterUser['email'], RegisterUser['email']>(
      {
        query: (email) => ({
          url: '/send-reset-password-token/',
          method: 'POST',
          body: { email: email },
        }),
      }
    ),
    setNewPassword: build.mutation<SetNewPassword, SetNewPassword>({
      query: (data) => ({
        url: '/set-new-password/',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

import { TableProps, Typography } from 'antd';
import { useState } from 'react';
import { DropDownInvoice } from 'shared/components/dropDown/ui/DropDownInvoices';
import {
  DropDownMenu,
  InvoicesSubmittedTableData,
  TableData,
} from 'shared/types/invoices';

import { SortedKey } from '../types/sortTypes';
import { CaretDownOutlined, CaretUpOutlined } from 'shared/assets/icons';

interface useInvoiceSubmittedTableProps {
  handleViewInvoice: (record: TableData) => void;
  handlePrintInvoice: (record: TableData) => void;
  handleRowClick: (record: TableData) => void;
  handleSort: (key: SortedKey, order: 'asc' | 'desc') => void;
  sortKey: SortedKey;
  sortOrder: 'asc' | 'desc';
}
type SetOpenKeys = (key: number | null) => void;

export const useInvoiceSubmittedTableConfig = ({
  handleViewInvoice,
  handlePrintInvoice,
  handleRowClick,
  handleSort,
  sortKey,
  sortOrder,
}: useInvoiceSubmittedTableProps) => {
  const [openKeys, setOpenKeys] = useState<number | null>(null);
  const getMenuItems = (
    record: TableData,
    setOpenKeys: SetOpenKeys
  ): DropDownMenu[] => [
    {
      label: <Typography className="">Open Invoice</Typography>,
      key: 'view',
      onClick: () => {
        handleViewInvoice(record);
        setOpenKeys(null);
      },
    },
    {
      label: <Typography className="">Download Factura</Typography>,
      key: 'print',
      onClick: () => {
        handlePrintInvoice(record);
        setOpenKeys(null);
      },
      disabled: !record.facturaUrl,
    },
  ];

  const tableColumns: TableProps<InvoicesSubmittedTableData>['columns'] = [
    {
      title: (
        <div className="table-title">
          Id
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'id' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('id', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'id' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('id', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: ['id'],
      key: 'id',
      align: 'center',
      width: '5%',
      ellipsis: false,
      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: (
        <div className="table-title">
          Company
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'company_config__company__name' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('company_config__company__name', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'company_config__company__name' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() =>
                handleSort('company_config__company__name', 'desc')
              }
            />
          </span>
        </div>
      ),
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      width: '15%',
      ellipsis: false,
      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: (
        <div className="table-title">
          Date Created
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'created' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('created', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'created' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('created', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: 'created',
      key: 'created',
      align: 'center',
      width: '15%',
      ellipsis: false,

      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
      render: (created: string) => <span>{created}</span>,
    },
    {
      title: (
        <div className="table-title">
          Date Submitted
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'submission_date' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('submission_date', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'submission_date' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('submission_date', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: 'submitted',
      key: 'submitted',
      align: 'center',
      width: '15%',
      ellipsis: false,

      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
      render: (submitted: string) => <span>{submitted}</span>,
    },
    {
      title: (
        <div className="table-title">
          Date Processed
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'processing_date' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('processing_date', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'processing_date' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('processing_date', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: 'dateProcessed',
      align: 'center',
      key: 'dateProcessed',
      width: '15%',
      ellipsis: false,

      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
      render: (dateProcessed: string) => <span>{dateProcessed}</span>,
    },
    {
      title: (
        <div className="table-title">
          Status
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'status' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('status', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'status' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('status', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '10%',
      ellipsis: false,

      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
      render: (status: string) => <span>{status}</span>,
    },

    {
      title: (
        <div className="table-title">
          Total Amount
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'total_amount' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('total_amount', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'total_amount' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('total_amount', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: 'total_amount',
      align: 'center',
      key: 'total_amount',
      width: '10%',
      ellipsis: false,

      onCell: (record: TableData) => ({
        onClick: () => {
          handleRowClick(record);
        },
      }),
      render: (totalAmount: string) => <span>{totalAmount || '---'}</span>,
    },
    {
      title: (
        <div className="table-title">
          Receipt Number
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'receipt_number' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('receipt_number', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'receipt_number' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('receipt_number', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: 'receipt_number',
      align: 'center',
      key: 'receipt_number',
      width: '10%',
      ellipsis: false,

      onCell: (record: TableData) => ({
        onClick: () => {
          handleRowClick(record);
        },
      }),
      render: (receiptNumber: string) => <span>{receiptNumber || '---'}</span>,
    },
    {
      title: 'Menu',
      key: 'menu',
      width: '5%',
      align: 'center',
      ellipsis: false,

      render: (record: TableData) => (
        <DropDownInvoice
          menuItems={getMenuItems(record, setOpenKeys)}
          companyId={record.id}
          setOpenKeys={setOpenKeys}
          openKeys={openKeys}
        />
      ),
    },
  ];

  return {
    tableColumns,
  };
};

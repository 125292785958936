import {
  ICompanyConfigResponse,
  IInvoiceAction,
  IInvoiceById,
} from 'features/invoices/types/invoicesTypes';
import React, { useEffect, useMemo, useState } from 'react';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(isoWeek);
interface InitialState {
  [key: string]: string | boolean | Dayjs;
}

export const useInvoiceCreate = ({
  companyConfig,
  invoice,
}: {
  companyConfig: ICompanyConfigResponse | null;
  invoice?: IInvoiceById | null;
}) => {
  const [initialFormFields, setInitialFormFields] = useState<IInvoiceAction[]>(
    []
  );
  const [formFields, setFormFields] = useState<IInvoiceAction[]>([]);
  const [deciderActions, setDeciderActions] = useState<IInvoiceAction[]>([]);
  const [deciderChildrenActions, setDeciderChildrenActions] = useState<
    IInvoiceAction[]
  >([]);
  const [actionsWithoutDeciders, setActionsWithoutDeciders] = useState<
    IInvoiceAction[]
  >([]);

  const filterActionsByType = (actions: IInvoiceAction[]): IInvoiceAction[] => {
    if (!actions.length) return [];

    return actions.filter(
      (config) =>
        config.field_type === 'text' ||
        config.field_type === 'select' ||
        config.field_type === 'radio' ||
        config.field_type === 'date' ||
        config.field_type === 'checkbox' ||
        config.field_type === 'number'
    );
  };

  useEffect(() => {
    const parsedConfigOnlyInvoice =
      companyConfig?.company_configs[0]?.actions?.filter(
        (action) => action.source === 'invoice'
      );

    const deciders = parsedConfigOnlyInvoice?.filter(
      (action) => action.is_decider
    );
    const deciderChildren = parsedConfigOnlyInvoice?.filter(
      (action) => action.decider
    );
    const actions = parsedConfigOnlyInvoice?.filter(
      (action) => !action.decider && !action.is_decider
    );
    setDeciderActions(filterActionsByType(deciders || []));
    setDeciderChildrenActions(filterActionsByType(deciderChildren || []));
    setActionsWithoutDeciders(filterActionsByType(actions || []));
  }, [companyConfig]);

  useEffect(() => {
    const initialActions = deciderActions.length
      ? deciderActions
      : actionsWithoutDeciders;

    setFormFields(initialActions || []);
    setInitialFormFields(initialActions || []);
    if (invoice?.id && initialActions.length) {
      const invoiceDeciders = invoice.actions.filter((item) => item.is_decider);
      const deciderValues: string[] = [];
      const mergedArray = deciderActions.map((item1) => {
        const matchingItem = invoiceDeciders.find(
          (item2) => item2.id === item1.id
        );
        if (matchingItem) {
          return { ...item1, ...matchingItem };
        }
        return item1;
      });
      if (mergedArray.length) {
        mergedArray.forEach((item) => {
          if (item.value) {
            deciderValues.push(item.value);
          }
        });
        addFieldsToForm(deciderValues, initialActions);
      }
    }
  }, [deciderActions, actionsWithoutDeciders, invoice]);

  const addFieldsToForm = (
    values: string[],
    initialActions?: IInvoiceAction[]
  ) => {
    if (values.length) {
      const deciderOptions = deciderActions.flatMap((item) => item.options);
      const labels = values
        .map(
          (value) =>
            deciderOptions.find((option) => option.value === value)?.label
        )
        .filter((label) => label !== undefined) as string[];
      const fieldsToForm = deciderChildrenActions.filter((item) =>
        labels.includes(item.decider_value)
      );

      const initData = initialActions?.length
        ? initialActions
        : initialFormFields;
      setFormFields([...initData, ...fieldsToForm, ...actionsWithoutDeciders]);
    }
  };

  const isHasTaxRegime = useMemo(() => {
    return !!companyConfig?.company_configs[0]?.actions?.filter((action) =>
      action.field_name.includes('tax_regime')
    ).length;
  }, [companyConfig]);
  const isHasCfdi = useMemo(() => {
    return !!companyConfig?.company_configs[0]?.actions?.filter((action) =>
      action.field_name.some((item) => item === 'cfdi')
    ).length;
  }, [companyConfig]);

  const format = 'DD-MM-YYYY';

  const setInitValues = (fieldType: string, value: string | undefined) => {
    if (fieldType === 'checkbox') {
      return value ? true : false;
    }
    if (fieldType === 'date') {
      return value ? dayjs(value, format) : '';
    }
    return value || '';
  };
  const initialState: InitialState = useMemo(
    () =>
      (formFields ?? []).reduce((acc, field) => {
        const valueInvoice = invoice?.actions?.find((item) =>
          item.field_name.includes(field.field_name[0])
        );
        acc[field.field_name[0] as string] = setInitValues(
          field.field_type,
          valueInvoice?.value || undefined
        );

        return acc;
      }, {} as InitialState),
    [formFields, invoice]
  );
  return {
    formFields,
    initialState,
    isHasTaxRegime,
    isHasCfdi,
    deciderActions,
    addFieldsToForm,
  };
};

import { AboutUs } from 'pages/AboutUs';
import { ChangePassword } from 'pages/ChangePassword';
import { ContactUs } from 'pages/ContactUs';
import { CreateInvoice } from 'pages/CreateInvoice';
import { EditInvoice } from 'pages/EditInvoice';
import { InvoiceDetail } from 'pages/InvoiceDetail';
import { Invoices } from 'pages/Invoices';
import { InvoicesSubmitted } from 'pages/InvoicesSubmitted';
import Login from 'pages/Login/Login';
import { Onboarding } from 'pages/Onboarding';
import { ProfilePage } from 'pages/Profile';
import { ProfileCreate } from 'pages/ProfileCreate';
import { ProfileEdit } from 'pages/ProfileEdit';
import { Register } from 'pages/Register';
import { ResetPassword } from 'pages/ResetPassword';
import { ResetPasswordComplete } from 'pages/ResetPasswordComplete';
import { ResetPasswordSuccessfully } from 'pages/ResetPasswordSuccessfully';
import { ResetPasswordVerify } from 'pages/ResetPasswordVerify';
import { SuccessChangePassword } from 'pages/SuccessChangePassword';
import { Successfully } from 'pages/Successfully';
import { Users } from 'pages/Users';
import { Verify } from 'pages/Verify';
import { WhyFactura } from 'pages/WhyFactura';
import { RouteProps } from 'react-router-dom';

export enum AppRoutes {
  INVOICES = '/invoices/saved',
  USERS = '/users',
  INVOICES_SUBMITTED = '/invoices/submitted',
  PROFILE = '/profile',
  PROFILE_CREATE = '/profile-create',
  INVOICE_CREATE = '/invoice/create',
  INVOICE = '/invoice/:id',
  INVOICE_EDIT = '/invoice/edit/:id',
  PROFILE_EDIT = '/profile/edit',
  CHANGE_PASSWORD = '/change-password',
  CHANGE_PASSWORD_SUCCESSFULLY = '/change-password-successfully',
  ABOUT_US = '/about-us',
  CONTACT_US = '/contact-us',
  WHY_FACTURA = '/why-use-factura-mas-rapido',
  ONBOARDING = '/onboarding',
}

export enum RegisterRoutes {
  LOGIN = '/login',
  REGISTER = '/register',
  VERIFY = '/register/verify',
  SUCCESSFULLY = '/register/successfully',
  RESET_PASSWORD = '/reset-password',
  RESET_PASSWORD_VERIFY = '/reset-password-verify',
  RESET_PASSWORD_SUCCESSFULLY = '/reset-password/successfully',
  RESET_PASSWORD_COMPLETE = '/reset-password/complete',
}

type CombinedRoutes = AppRoutes | RegisterRoutes;

export const RoutePath: Record<CombinedRoutes, string> = {
  [AppRoutes.INVOICES]: '/',
  [AppRoutes.USERS]: '/users',
  [AppRoutes.INVOICES_SUBMITTED]: '/invoices/submitted',
  [AppRoutes.PROFILE]: '/profile',
  [AppRoutes.PROFILE_EDIT]: '/profile/edit',
  [AppRoutes.PROFILE_CREATE]: '/profile-create',
  [AppRoutes.INVOICE_CREATE]: '/invoice/create',
  [AppRoutes.INVOICE]: '/invoice/:id',
  [AppRoutes.INVOICE_EDIT]: '/invoice/edit/:id',
  [AppRoutes.CHANGE_PASSWORD]: '/change-password',
  [AppRoutes.CHANGE_PASSWORD_SUCCESSFULLY]: '/change-password-successfully',
  [AppRoutes.ABOUT_US]: '/about-us',
  [AppRoutes.CONTACT_US]: '/contact-us',
  [AppRoutes.WHY_FACTURA]: '/why-use-factura-mas-rapido',
  [AppRoutes.ONBOARDING]: '/onboarding',

  [RegisterRoutes.LOGIN]: '/login',
  [RegisterRoutes.REGISTER]: '/register',
  [RegisterRoutes.VERIFY]: '/register/verify',
  [RegisterRoutes.SUCCESSFULLY]: '/register/successfully',
  [RegisterRoutes.RESET_PASSWORD]: '/reset-password',
  [RegisterRoutes.RESET_PASSWORD_SUCCESSFULLY]: '/reset-password/successfully',
  [RegisterRoutes.RESET_PASSWORD_VERIFY]: '/reset-password-verify',
  [RegisterRoutes.RESET_PASSWORD_COMPLETE]: '/reset-password/complete',
};

export const routeAppConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.INVOICES]: {
    path: RoutePath['/invoices/saved'],
    element: <Invoices />,
  },
  [AppRoutes.USERS]: {
    path: RoutePath['/users'],
    element: <Users />,
  },
  [AppRoutes.INVOICES_SUBMITTED]: {
    path: RoutePath['/invoices/submitted'],
    element: <InvoicesSubmitted />,
  },
  [AppRoutes.PROFILE]: {
    path: RoutePath['/profile'],
    element: <ProfilePage />,
  },
  [AppRoutes.PROFILE_EDIT]: {
    path: RoutePath['/profile/edit'],
    element: <ProfileEdit />,
  },
  [AppRoutes.PROFILE_CREATE]: {
    path: RoutePath['/profile-create'],
    element: <ProfileCreate />,
  },
  [AppRoutes.INVOICE_CREATE]: {
    path: RoutePath['/invoice/create'],
    element: <CreateInvoice />,
  },
  [AppRoutes.INVOICE]: {
    path: RoutePath['/invoice/:id'],
    element: <InvoiceDetail />,
  },
  [AppRoutes.INVOICE_EDIT]: {
    path: RoutePath['/invoice/edit/:id'],
    element: <EditInvoice />,
  },
  [AppRoutes.CHANGE_PASSWORD]: {
    path: RoutePath['/change-password'],
    element: <ChangePassword />,
  },
  [AppRoutes.CHANGE_PASSWORD_SUCCESSFULLY]: {
    path: RoutePath['/change-password-successfully'],
    element: <SuccessChangePassword />,
  },
  [AppRoutes.ABOUT_US]: {
    path: RoutePath['/about-us'],
    element: <AboutUs />,
  },
  [AppRoutes.CONTACT_US]: {
    path: RoutePath['/contact-us'],
    element: <ContactUs />,
  },
  [AppRoutes.WHY_FACTURA]: {
    path: RoutePath['/why-use-factura-mas-rapido'],
    element: <WhyFactura />,
  },

  [AppRoutes.ONBOARDING]: {
    path: RoutePath['/onboarding'],
    element: <Onboarding />,
  },
};
export const routeRegisterConfig: Record<RegisterRoutes, RouteProps> = {
  [RegisterRoutes.LOGIN]: {
    path: RoutePath['/login'],
    element: <Login />,
  },
  [RegisterRoutes.REGISTER]: {
    path: RoutePath['/register'],
    element: <Register />,
  },
  [RegisterRoutes.VERIFY]: {
    path: RoutePath['/register/verify'],
    element: <Verify />,
  },
  [RegisterRoutes.SUCCESSFULLY]: {
    path: RoutePath['/register/successfully'],
    element: <Successfully />,
  },

  [RegisterRoutes.RESET_PASSWORD]: {
    path: RoutePath['/reset-password'],
    element: <ResetPassword />,
  },
  [RegisterRoutes.RESET_PASSWORD_SUCCESSFULLY]: {
    path: RoutePath['/reset-password/successfully'],
    element: <ResetPasswordSuccessfully />,
  },
  [RegisterRoutes.RESET_PASSWORD_VERIFY]: {
    path: RoutePath['/reset-password-verify'],
    element: <ResetPasswordVerify />,
  },
  [RegisterRoutes.RESET_PASSWORD_COMPLETE]: {
    path: RoutePath['/reset-password/complete'],
    element: <ResetPasswordComplete />,
  },
};

import React from 'react';
import profileImg from '../../../shared/assets/images/profile.png';
import invoiceDetailImg from '../../../shared/assets/images/invoice_detail.png';
import { ISteps } from '../types/onboardingTypes';
import { HandIcon } from 'shared/assets/icons';
export const Step1: React.FC<ISteps> = ({ setNextStep }) => {
  return (
    <div className="onboarding-step">
      <div className="img img-profile">
        <img src={profileImg} alt="profile-img" />
        <div className="description description-profile">
          <HandIcon className="icon-hand" />
          Fill in your Profile once, forget about it and we will do the rest
        </div>
      </div>
      <div className="img img-invoice">
        <img src={invoiceDetailImg} alt="invoice-img" />
        <div className="description description-invoice">
          <HandIcon className="icon-hand" />
          You may always change Tax Regime and CFDI for each invoice with two
          clicks, if using more than one Tax Regime or CFDI are allowed under
          their same RFC & SAT details
        </div>
      </div>
    </div>
  );
};

import { invoiceApi } from './invoiceApi';

const {
  useGetInvoicesSavedQuery,
  useLazyGetInvoicesSavedQuery,
  useGetCompaniesQuery,
  useLazyGetCompanyConfigQuery,
  useSaveInvoiceMutation,
  useSubmitInvoiceMutation,
  useLazyGetInvoiceByIdQuery,
  useUpdateInvoiceMutation,
  useSubmitBulkInvoicesMutation,
} = invoiceApi;

export {
  invoiceApi,
  useGetInvoicesSavedQuery,
  useLazyGetInvoicesSavedQuery,
  useGetCompaniesQuery,
  useLazyGetCompanyConfigQuery,
  useSaveInvoiceMutation,
  useSubmitInvoiceMutation,
  useLazyGetInvoiceByIdQuery,
  useUpdateInvoiceMutation,
  useSubmitBulkInvoicesMutation,
};

import React, { useEffect } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

const { error } = Modal;

interface IModalErrorProps {
  title: string;
  content: string | React.ReactNode;
  onOk: () => void;
}

export const ModalError: React.FC<IModalErrorProps> = ({
  content,
  onOk,
  title,
}) => {
  useEffect(() => {
    if (!content && !title) {
      return;
    }
    const config = {
      title: <div className="extrabold-24">{title}</div>,
      icon: <ExclamationCircleFilled />,
      content: <div className="text-bold">{content}</div>,
      onOk,
    };
    error(config);
  }, []);
  return null;
};

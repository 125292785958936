import { authApi } from './authApi';

const {
  useRegisterUserMutation,
  useResendEmailVerificationMutation,
  useVerifyEmailMutation,
  useLoginMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useSetNewPasswordMutation,
  useVerifyTokenMutation,
} = authApi;

export {
  authApi,
  useRegisterUserMutation,
  useResendEmailVerificationMutation,
  useVerifyEmailMutation,
  useLoginMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useSetNewPasswordMutation,
  useVerifyTokenMutation,
};

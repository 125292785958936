import { Alert, message, Typography } from 'antd';
import { useResendEmailVerificationMutation } from 'features/auth/api';
import React, { useEffect, useState } from 'react';
import { Logo } from 'shared/assets/icons';
import { Button } from 'shared/components/button';
import { CustomLink } from 'shared/components/customLink';
import { ErrorMessage } from 'shared/components/errorMessage';
import { ErrorVerify } from 'shared/components/errorVerify';
import { Loading } from 'shared/components/loading';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { USER_EMAIL } from 'shared/constants';
import { getLocalStorage } from 'shared/libs/storageService/localStorage';

const Verify = () => {
  const [email, setEmail] = useState('');
  const [fetchResendEmail, { isLoading, isError, isSuccess }] =
    useResendEmailVerificationMutation();
  useEffect(() => {
    const email = getLocalStorage(USER_EMAIL);
    setEmail(email);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      message.success(
        `Email to complete registration was send to email ${email}. Check your email.`
      );
    }
  }, [isSuccess]);

  const resendEmail = async () => {
    await fetchResendEmail(email);
  };
  return (
    <div className="center-page flex-column">
      {!isError && (
        <>
          <div className="logo-register">
            <Logo />
          </div>
          <Typography.Paragraph className="extrabold-24 text-center">
            {`An email to complete your registration has been sent to ${email}.`}
          </Typography.Paragraph>
          <Typography.Paragraph className="extrabold-24 text-center">
            Please check your mailbox and junk folder.
          </Typography.Paragraph>

          <Button
            onClick={resendEmail}
            disabled={isLoading}
            className="mt-16 flex-row"
          >
            Send Verification Link
            {isLoading && <Loading className="ml-12 spin-white" size="small" />}
          </Button>
        </>
      )}

      {isError && <ErrorVerify />}
    </div>
  );
};

export default Verify;

import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import { Suspense } from 'react';
import AppLayout from 'shared/layouts/appLayout/ui/AppLayout';
import {
  routeAppConfig,
  routeRegisterConfig,
} from 'shared/config/routerConfig';
import RegisterLayout from 'shared/layouts/registerLayout/ui/RegisterLayout';
import { NotFoundPage } from 'pages/NotFounPage';
const ClientRoutes = () => {
  const router = createBrowserRouter([
    {
      element: <AppLayout />,
      children: Object.values(routeAppConfig).map(({ path, element }) => ({
        path: path,
        element: (
          <Suspense fallback={<div>Loading...</div>}>{element}</Suspense>
        ),
      })),
    },
    {
      element: <RegisterLayout />,
      children: Object.values(routeRegisterConfig).map(({ path, element }) => ({
        path: path,
        element: (
          <Suspense fallback={<div>Loading...</div>}>{element}</Suspense>
        ),
      })),
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default ClientRoutes;

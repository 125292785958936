import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Radio, RadioChangeEvent, Select, DatePicker } from 'antd';
import { CustomLabel } from 'shared/components/customLabel';
import { Button } from 'shared/components/button';
import { Loading } from 'shared/components/loading';

import '../styles/profileForm.scss';
import { rfcRegEx } from 'shared/regExpText';
import {
  profileType,
  getTaxRegimCompanyCodes,
  getTaxRegimPersonalCodes,
  getCfdiPersonalOptions,
  getCfdiCompanyOptions,
} from '../formConfig/formConfig';
import { ProfileType } from '../types/formtypes';
import { fieldsAddress, formFieldsUserName } from '../formConfig/formFields';
import { IProfileData } from 'shared/types/profile';
import { useGetProfileCodesQuery } from 'features/profile/api';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import { IProfile } from 'features/profile/types/profileTypes';
import { deepCompare } from '../libs/compare';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(isoWeek);

interface IProfileFormProps {
  handleSubmit: (value: IProfileData) => Promise<void>;
  profile: IProfile | null;
  isLoading: boolean;
  handleCancel?: () => void;
}

export const ProfileForm: React.FC<IProfileFormProps> = ({
  handleSubmit,
  isLoading,
  profile,
  handleCancel,
}) => {
  const [typeUser, setTypeUser] = useState<ProfileType>(
    profile?.profileType || 'personal'
  );
  const format = 'YYYY-MM-DD';

  const [form] = Form.useForm<IProfileData>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { data: codes } = useGetProfileCodesQuery({});
  const initialState: IProfileData = {
    birthDate: profile?.birthDate ? dayjs(profile?.birthDate, format) : '',
    firstSurname: profile?.firstSurname || '',
    name: profile?.name || '',
    secondLastName: profile?.secondLastName || '',
    rfc: profile?.rfc || '',
    profileType: profile?.profileType || 'personal',
    taxRegime: profile?.taxRegimeId || '',
    cfdi: profile?.cfdiId || '',
    legalEntityName: profile?.legalEntityName || '',
    address: {
      andStreet: profile?.address.andStreet || '',
      betweenStreet: profile?.address.betweenStreet || '',
      colonyName: profile?.address.colonyName || '',
      exteriorNumber: profile?.address.exteriorNumber || '',
      federalEntityName: profile?.address.federalEntityName || '',
      interiorNumber: profile?.address.interiorNumber || '',
      localityName: profile?.address.localityName || '',
      municipalityName: profile?.address.municipalityName || '',
      postalCode: profile?.address.postalCode || '',
      roadName: profile?.address.roadName || '',
      roadType: profile?.address.roadType || '',
      state: profile?.address.state || '',
    },
  };

  const taxRegimPersonalOptions = useMemo(() => {
    return getTaxRegimPersonalCodes(codes?.tax_regime.personal || []);
  }, [codes]);
  const taxRegimCompanyOptions = useMemo(() => {
    return getTaxRegimCompanyCodes(codes?.tax_regime.company || []);
  }, [codes]);
  const cfdiPersonalOptions = useMemo(() => {
    return getCfdiPersonalOptions(codes?.cfdi.personal || []);
  }, [codes]);
  const cfdiCompanyOptions = useMemo(() => {
    return getCfdiCompanyOptions(codes?.cfdi.company || []);
  }, [codes]);

  const taxRegimOptions = useMemo(() => {
    return typeUser === 'personal'
      ? taxRegimPersonalOptions
      : taxRegimCompanyOptions;
  }, [typeUser, taxRegimPersonalOptions, taxRegimCompanyOptions]);

  const cfdiOptions = useMemo(() => {
    return typeUser === 'personal' ? cfdiPersonalOptions : cfdiCompanyOptions;
  }, [typeUser, cfdiPersonalOptions, cfdiCompanyOptions]);

  const rfcMinLength = 13;
  const rfcMaxLength = 13;

  useEffect(() => {
    if (!profile?.user.id) {
      form.setFieldsValue({
        profileType: typeUser,
        taxRegime:
          taxRegimOptions.length > 0 ? taxRegimOptions[0].value : undefined,
        cfdi: cfdiOptions.length > 0 ? cfdiOptions[0].value : undefined,
        legalEntityName:
          typeUser === 'company'
            ? form.getFieldValue('legalEntityName') || ''
            : undefined,
      });
      handleValidateForm();
    }
  }, [
    profile,
    typeUser,
    taxRegimOptions,
    cfdiOptions,
    taxRegimPersonalOptions,
    taxRegimCompanyOptions,
  ]);

  useEffect(() => {
    if (profile?.user.id && profile.profileType !== typeUser) {
      form.setFieldsValue({
        taxRegime:
          taxRegimOptions.length > 0 ? taxRegimOptions[0].value : undefined,
        cfdi: cfdiOptions.length > 0 ? cfdiOptions[0].value : undefined,
        legalEntityName:
          typeUser === 'company' ? profile.legalEntityName || '' : undefined,
      });
    } else if (profile?.user.id && profile.profileType === typeUser) {
      form.setFieldsValue({
        taxRegime: taxRegimOptions.length > 0 ? profile.taxRegimeId : undefined,
        cfdi: cfdiOptions.length > 0 ? profile.cfdiId : undefined,
        legalEntityName:
          typeUser === 'company' ? profile.legalEntityName || '' : undefined,
      });
    }
    handleValidateForm();
  }, [
    profile,
    typeUser,
    taxRegimOptions,
    cfdiOptions,
    taxRegimPersonalOptions,
    taxRegimCompanyOptions,
  ]);

  const compareFormWithInitialState = (initialState: IProfileData) => {
    const values = form.getFieldsValue();

    const { hasChanges, changes } = deepCompare(values, initialState);

    return { isDifferent: hasChanges, changedFields: changes };
  };

  const handleValidateForm = () => {
    const values = form.getFieldsValue();
    const isNameFilled = values.name !== '';
    const isFirstSurnameFilled = values.firstSurname !== '';
    const isBirthDateFilled =
      values.birthDate !== '' && values.birthDate !== null;
    const isRfcFilled = values.rfc !== '';
    const isLegalEntityNameFilled =
      typeUser === 'company' ? values.legalEntityName !== '' : true;
    const isAddressFilled = Object.keys(values.address).every((key) => {
      if (key === 'interiorNumber' || key === 'localityName') {
        return true;
      }
      return (values.address as Record<string, string>)[key] !== '';
    });

    const isButtonActive =
      isNameFilled &&
      isRfcFilled &&
      isFirstSurnameFilled &&
      !!isBirthDateFilled &&
      isLegalEntityNameFilled &&
      isAddressFilled;
    if (profile?.user.id) {
      const { isDifferent } = compareFormWithInitialState(initialState);
      return setIsButtonDisabled(!isDifferent || !isButtonActive);
    } else {
      setIsButtonDisabled(!isButtonActive);
    }
  };

  const handleFormValuesChange = () => {
    handleValidateForm();
  };
  const onChangeUserType = (e: RadioChangeEvent) => {
    setTypeUser(e.target.value);
  };

  const handleSubmitForm = (value: IProfileData) => {
    // const { changedFields } = compareFormWithInitialState(initialState);
    // if (profile?.user.id) {
    //   handleSubmit(changedFields);
    //   return;
    // }
    handleSubmit(value);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: '100%', margin: '0 auto' }}
      initialValues={initialState}
      onFinish={handleSubmitForm}
      onValuesChange={handleFormValuesChange}
    >
      {profile?.user.email && (
        <div className="form-read-only">
          <CustomLabel text={'Email'} />
          <span className="form-email">{profile?.user.email}</span>
        </div>
      )}
      <div className="form-space">
        {formFieldsUserName.map((profileField, idx) => (
          <div key={'profile-wrapper' + idx} className="form-flex">
            {profileField.map((item, index) => {
              if (item.name !== 'birthDate') {
                return (
                  <React.Fragment key={item.name + `${index}`}>
                    <Form.Item
                      label={<CustomLabel text={item.label || ''} />}
                      name={item.name}
                      rules={item.rules}
                      validateTrigger={item.validateTrigger}
                      className={item.formItemClassName || ''}
                    >
                      <Input
                        type={item.inputType}
                        maxLength={item.maxLength}
                        className="input"
                        size="large"
                      />
                    </Form.Item>
                  </React.Fragment>
                );
              } else {
                return (
                  <Form.Item
                    key={item.name + `${index}`}
                    label={<CustomLabel text={item.label} />}
                    name={item.name}
                    rules={item.rules}
                    validateTrigger={item.validateTrigger}
                    className={item.formItemClassName || ''}
                  >
                    <DatePicker
                      className="input"
                      size="large"
                      format={format}
                    />
                  </Form.Item>
                );
              }
            })}
          </div>
        ))}
      </div>

      {fieldsAddress.map((address, idx) => {
        // if (!Array.isArray(address)) {
        //   return (
        //     <React.Fragment key={address.label + 'address' + `${idx}`}>
        //       <Form.Item
        //         label={<CustomLabel text={address.label} />}
        //         name={address.name}
        //         rules={address.rules}
        //         validateTrigger="onSubmit"
        //       >
        //         <Input
        //           type={address.inputType}
        //           maxLength={address.maxLength}
        //           className="input"
        //           size="large"
        //         />
        //       </Form.Item>
        //     </React.Fragment>
        //   );
        // }
        return (
          <div className="form-flex" key={`address-group-${idx}`}>
            {address.map((item, idx) => (
              <React.Fragment key={item.label + `flx ${idx}`}>
                <Form.Item
                  label={<CustomLabel text={item.label} />}
                  name={item.name}
                  rules={item.rules}
                  validateTrigger="onSubmit"
                  className={item.formItemClassName || ''}
                >
                  <Input
                    type={item.inputType}
                    maxLength={item.maxLength}
                    className="input"
                    size="large"
                  />
                </Form.Item>
              </React.Fragment>
            ))}
          </div>
        );
      })}

      <Form.Item
        label={<CustomLabel text="RFC Number" />}
        name="rfc"
        rules={[
          { required: true, message: 'Please input your RFC Number!' },
          {
            validator: (_, value) => {
              if (!value || rfcRegEx.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(
                'Only numbers and Latin letters are allowed in RFC Number!'
              );
            },
          },
          {
            min: rfcMinLength,
            max: rfcMaxLength,
            message: `The RFC Number must be ${rfcMaxLength} characters.`,
          },
        ]}
        validateTrigger="onSubmit"
        className="flex-04"
      >
        <Input
          type="text"
          maxLength={rfcMaxLength}
          className="input"
          size="large"
        />
      </Form.Item>
      <Form.Item
        label={<CustomLabel text="Account Type" />}
        name="profileType"
        rules={[{ required: true, message: 'Please select type!' }]}
      >
        <Radio.Group onChange={onChangeUserType} value={typeUser}>
          <Radio value={profileType.personalUser}>Personal User</Radio>
          <Radio value={profileType.companyUser}>Company User</Radio>
        </Radio.Group>
      </Form.Item>
      {typeUser === 'company' && (
        <Form.Item
          label={<CustomLabel text={'Legal Entity Name'} />}
          name="legalEntityName"
          rules={[
            { required: true, message: 'Please input your Legal Entity Name!' },
            {
              min: 1,
              max: 512,
              message: `Legal Entity Name must be between ${1} and ${512} characters.`,
            },
          ]}
          validateTrigger="onSubmit"
        >
          <Input type="text" maxLength={512} className="input" size="large" />
        </Form.Item>
      )}
      <div className="form-flex">
        <Form.Item
          label={<CustomLabel text="Tax Regime" />}
          name="taxRegime"
          rules={[{ required: true }]}
          className="flex-05"
        >
          <Select
            key={taxRegimOptions.length + typeUser}
            style={{ width: '100%' }}
            options={taxRegimOptions}
            size="large"
          />
        </Form.Item>
        <Form.Item
          label={<CustomLabel text="CFDI" />}
          name="cfdi"
          rules={[{ required: true }]}
          className="flex-05"
        >
          <Select
            key={cfdiOptions.length + typeUser}
            style={{ width: '100%' }}
            options={cfdiOptions}
            size="large"
          />
        </Form.Item>
      </div>

      {!profile?.user.id ? (
        <Button
          type="submit"
          className="flex-row mb-24"
          disabled={isButtonDisabled || isLoading}
        >
          Save
          {isLoading && <Loading className="ml-12 spin-white" size="small" />}
        </Button>
      ) : (
        <div className="buttons-wrapper buttons-wrapper-profile mt-16">
          <Button
            type="button"
            onClick={handleCancel}
            className="flex-row mb-24"
            disabled={isLoading}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            className="flex-row mb-24"
            disabled={isButtonDisabled || isLoading}
          >
            Update Profile
            {isLoading && <Loading className="ml-12 spin-white" size="small" />}
          </Button>
        </div>
      )}
    </Form>
  );
};

import React from 'react';
import '../styles/whyFactura.scss';
import { ConvenienceIcon, HardWork, TimeBack } from 'shared/assets/icons';
const WhyFactura = () => {
  return (
    <div className="w-100 lh-15">
      <div className="factura-top-section">
        <h2 className="mb-16 text-center">Why Use Factura Mas Rapido?</h2>
        <p>
          The Factura Mas Rapido platform is an Artificial Intelligence factura
          support software, which provides individual users and businesses with
          the ultimate convenience when obtaining and submitting your monthly
          facturas to the SAT.
          <br />
          <br />
        </p>
        <p>
          Just imagine you had a sophisticated piece of software which takes all
          the <strong>hard work</strong>, <strong>wasted time</strong> and{' '}
          <strong>website searching</strong> away from the equation when you
          submit your monthly facturas.
          <br />
          <br />
        </p>
        <p>
          Well you no longer need to imagine, as the Factura Mas Rapido platform
          is here to serve your factura needs….
          <br />
          <br />
          <br />
        </p>
      </div>

      <div className="factura-bottom-section">
        <div>
          <div>
            <h2 className="mb-16 text-center">Convenience </h2>
            <div className="factura-icon">
              <ConvenienceIcon />
            </div>
          </div>
          <p>
            Factura Mas Rapido’s platform lets you obtain and submit all your
            facturas from one place.
          </p>
        </div>
        <div>
          <div>
            <h2 className="mb-16 text-center">
              We Do <strong>All</strong> The <strong>Hard Work</strong>
            </h2>
            <div className="factura-icon">
              <HardWork />
            </div>
          </div>

          <p>
            Factura Mas Rapido’s innovative technology can search hundreds of
            websites within seconds and once the websites are found, our system
            automatically inputs the receipt information and your details to
            generate a factura for you. All your facturas are then sent to your
            SAT account simultaneously via each website.
            <br />
            <br />
          </p>
          <p>
            You can also download your officially submitted SAT facturas for
            your own records.
          </p>
        </div>
        <div>
          <div>
            <h2 className="mb-16 text-center">
              Get Your <strong>Time</strong> Back
            </h2>
            <div className="factura-icon">
              <TimeBack />
            </div>
          </div>

          <p>
            Factura Mas Rapido’s platform saves you so much time and wasted
            effort. You no longer need to visit numerous websites to obtain a
            factura… We do it all for you with one click of a button!
            <br />
            <br />
          </p>
          <p>
            Users stand to benefit significantly, as the use of our platform and
            app could on average provide up to a <strong>2,200%</strong> quicker
            time-saving process and exceptionally smooth experience than the
            current way.{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyFactura;

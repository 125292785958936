import React from 'react';
import { Logo } from 'shared/assets/icons';
import { ResetPasswordForm } from 'widgets/ResetPasswordForm';

const ResetPassword = () => {
  return (
    <div className="pageContent center-page">
      <div className="logo-register">
        <Logo />
      </div>
      <h2 className="header-text">Reset Password</h2>
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPassword;

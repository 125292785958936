import {
  useGetCompaniesQuery,
  useLazyGetCompanyConfigQuery,
  useSaveInvoiceMutation,
} from 'features/invoices/api';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'shared/components/loading';
import { ModalError } from 'shared/components/modalError';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import ProfileInfo from 'widgets/ProfileInfo/ui/ProfileInfo';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { InvoiceCreateForm } from 'widgets/InvoiceCreateForm';
import { ICompanyConfigResponse } from 'features/invoices/types/invoicesTypes';
import { message } from 'antd';
import { useAppSelector } from 'app/providers/store';
import { useGetProfileCodesQuery } from 'features/profile/api';

const CreateInvoice = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const [companyConfig, setCompanyConfig] =
    useState<ICompanyConfigResponse | null>(null);
  const navigate = useNavigate();
  const { profile: profileData } = useAppSelector(
    (state) => state.profileReducer
  );
  const { data: profileCodes } = useGetProfileCodesQuery({});

  const groupedTaxRegimeOptions = {
    personal:
      profileCodes?.tax_regime.personal?.map((item) => ({
        value: item.code,
        label: `${item.code}: ${item.description}`,
      })) || [],
    company:
      profileCodes?.tax_regime.company?.map((item) => ({
        value: item.code,
        label: `${item.code}: ${item.description}`,
      })) || [],
  };
  const groupedCfdiOptions = {
    personal:
      profileCodes?.cfdi.personal?.map((item) => ({
        value: item.code,
        label: `${item.code}: ${item.description}`,
      })) || [],
    company:
      profileCodes?.cfdi.company?.map((item) => ({
        value: item.code,
        label: `${item.code}: ${item.description}`,
      })) || [],
  };
  const currentTaxRegimeCode =
    profileCodes?.tax_regime.company.find(
      (item) => item.id === profileData?.taxRegimeId
    )?.code ||
    profileCodes?.tax_regime.personal.find(
      (item) => item.id === profileData?.taxRegimeId
    )?.code;

  const currentCfdiCode =
    profileCodes?.cfdi.company.find((item) => item.id === profileData?.cfdiId)
      ?.code ||
    profileCodes?.cfdi.personal.find((item) => item.id === profileData?.cfdiId)
      ?.code;

  const {
    data: companiesData,
    isLoading: isCompaniesLoading,
    isFetching: isCompaniesFetching,
    isSuccess: isCompaniesSuccess,
    refetch: refetchCompanies,
  } = useGetCompaniesQuery({});

  const [fetchCompanyConfig, { isLoading: isLoadingConfig, isSuccess }] =
    useLazyGetCompanyConfigQuery();
  const [fetchCreateInvoice] = useSaveInvoiceMutation();

  const getCompanyConfig = async (companyId: number) => {
    try {
      const data = await fetchCompanyConfig(companyId).unwrap();

      setCompanyConfig(data);
    } catch (e) {
      console.error(e, 'Create invoice get config failed');
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      getCompanyConfig(selectedCompany);
    }
  }, [selectedCompany]);

  const navigateToSavedInvoices = () => {
    setModalVisible(false);
    navigate(RoutePath['/invoices/saved']);
  };

  useEffect(() => {
    refetchCompanies();
  }, [refetchCompanies]);

  useEffect(() => {
    if (
      !isCompaniesLoading &&
      !isCompaniesFetching &&
      isCompaniesSuccess &&
      companiesData &&
      !companiesData.results.length &&
      !isModalVisible
    ) {
      setModalVisible(true);
    }
  }, [
    isCompaniesLoading,
    isCompaniesFetching,
    companiesData,
    isModalVisible,
    isCompaniesSuccess,
  ]);

  const handleCompanyChange = (value: number) => {
    setSelectedCompany(value);
  };

  const handleCreateInvoice = async (data: FormData) => {
    if (!companyConfig?.id) return;
    try {
      await fetchCreateInvoice({
        company_config: companyConfig.company_configs[0].id,
        status: 'draft',
        data: data,
      }).unwrap();
      message.success('Invoice created successfully!');
      navigate(RoutePath['/invoices/saved']);
    } catch (e) {
      throw e;
    }
  };

  if (isCompaniesLoading || isCompaniesFetching) {
    return (
      <div className="center-page">
        <Loading size="large" />
      </div>
    );
  }
  return (
    <div>
      {isModalVisible && !companiesData?.results.length && (
        <ModalError
          title="No available Companies."
          content="Contact to Factura Mas Rapido Support Team"
          onOk={navigateToSavedInvoices}
        />
      )}
      <ArrowLeftOutlined
        className="arrow-back"
        onClick={navigateToSavedInvoices}
      />

      {profileData?.name && <ProfileInfo profile={profileData}></ProfileInfo>}
      <hr></hr>
      {!!companiesData?.results.length && (
        <InvoiceCreateForm
          companies={companiesData.results}
          handleCompanyChange={handleCompanyChange}
          companyConfig={companyConfig}
          isLoadingConfig={isLoadingConfig}
          navigateToSavedInvoices={navigateToSavedInvoices}
          handleCreateInvoice={handleCreateInvoice}
          groupedTaxRegimeOptions={groupedTaxRegimeOptions}
          groupedCfdiOptions={groupedCfdiOptions}
          initialCfdiValue={currentCfdiCode || ''}
          initialTaxRegimeValue={currentTaxRegimeCode || ''}
        />
      )}
    </div>
  );
};

export default CreateInvoice;

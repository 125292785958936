import { IProfile } from 'features/profile/types/profileTypes';
import React from 'react';
import { ProfileKeysToLabel } from 'shared/types/profile';
import '../styles/profileInfo.scss';

interface IProfileInfoProps {
  profile: IProfile;
  title?: string;
}

const ProfileInfo: React.FC<IProfileInfoProps> = ({ profile, title }) => {
  const mainProfileInfo = Object.keys(profile).filter(
    (item) =>
      item !== 'address' &&
      item !== 'user' &&
      item !== 'cfdiId' &&
      item !== 'taxRegimeId' &&
      item !== 'isOnboardingPassed'
  );
  const profileAddress = Object.keys(profile.address);
  return (
    <div className="profile-info">
      <h2 className="profile-info-title">
        {title || 'Information about your profile'}
      </h2>
      <div className="profile-info-list-container">
        <ul className="profile-info-list">
          {mainProfileInfo.map((item, idx) => {
            const key = item as keyof IProfile;
            if (profile[key] as string) {
              return (
                <li className="profile-info-list-item" key={`${item}-${idx}`}>
                  <span className="profile-info-label">
                    {
                      ProfileKeysToLabel[
                        item as keyof typeof ProfileKeysToLabel
                      ]
                    }
                    :
                  </span>
                  <span className="profile-info-data">
                    {profile[key] as string}
                  </span>
                </li>
              );
            }
          })}
        </ul>
        <ul className="profile-info-list">
          {profileAddress.map((item, idx) => {
            const key = item as keyof IProfile['address'];
            if (profile.address[key] as string) {
              return (
                <li className="profile-info-list-item" key={`${item}-${idx}`}>
                  <span className="profile-info-label">
                    {
                      ProfileKeysToLabel[
                        item as keyof typeof ProfileKeysToLabel
                      ]
                    }
                    :
                  </span>
                  <span className="profile-info-data">
                    {profile.address[key] as string}
                  </span>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProfileInfo;

import { VerifyEmailResponse } from 'features/auth/types/authTypes';
import { getCookie, removeCookie, setCookie } from '../storageService/cookie';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'shared/constants';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_PATH,
  prepareHeaders: (headers) => {
    const token = getCookie(ACCESS_TOKEN);

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = getCookie(REFRESH_TOKEN);
    const refreshResult = (await baseQuery(
      {
        url: '/refresh-token/',
        method: 'POST',
        body: { refresh: refreshToken },
      },
      api,
      extraOptions
    )) as { data: VerifyEmailResponse };

    if (refreshResult.data) {
      setCookie(ACCESS_TOKEN, refreshResult.data.access);
      setCookie(REFRESH_TOKEN, refreshResult.data.refresh);
      result = await baseQuery(args, api, extraOptions);
    } else {
      removeCookie(ACCESS_TOKEN);
      removeCookie(REFRESH_TOKEN);
      window.location.href = RoutePath['/login'];
      console.error('REFRESH ERROR');
    }
  }

  return result;
};

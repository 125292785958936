import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <span className="text-size-80">🤔</span>

      <Typography className="extrabold-24">404 - Page Not Found</Typography>
      <Typography className="normal-16">
        The page you are looking for doesn't exist.
      </Typography>
      <Typography className="normal-16">
        Go back to the <Link to={RoutePath['/invoices/saved']}>Home</Link>.
      </Typography>
    </div>
  );
};

export default NotFoundPage;

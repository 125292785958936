import React from 'react';
import { Outlet } from 'react-router-dom';
import { withAuth } from 'shared/hocs/withAuth';

const RegisterLayout = () => {
  return (
    <div className="pageWrapper register-wrapper">
      <div className="pageContainer">
        <Outlet />
      </div>
    </div>
  );
};

export default withAuth(RegisterLayout);

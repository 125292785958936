import { Dayjs } from 'dayjs';

export interface IProfileData {
  rfc: string;
  profileType: 'personal' | 'company';
  taxRegime: string | number;
  cfdi: string | number;
  name: string;
  firstSurname: string;
  secondLastName?: string;
  birthDate: string | Dayjs;
  legalEntityName?: string;

  address: {
    andStreet: string;
    betweenStreet: string;
    exteriorNumber: string;
    interiorNumber: string;
    roadName: string;
    roadType: string;
    postalCode: string;
    localityName: string;
    state?: string;
    municipalityName: string;
    federalEntityName: string;
    colonyName: string;
  };
}

export enum ProfileKeysToLabel {
  rfc = 'RFC',
  profileType = 'Profile Type',
  taxRegime = 'Tax Regime',
  cfdi = 'CFDI',
  name = 'Name',
  firstSurname = 'First Surname',
  secondLastName = 'Second Last Name',
  birthDate = 'Date of Birth',
  legalEntityName = 'Legal Entity Name',
  andStreet = 'And Street',
  betweenStreet = 'Between Street',
  exteriorNumber = 'Exterior Number',
  interiorNumber = 'Interior Number',
  roadName = 'Road Name',
  roadType = 'Road Type',
  postalCode = 'Postal Code',
  localityName = 'Name of the Locality',
  state = 'State',
  municipalityName = 'Name of the Municipality or Territorial Demarcation',
  federalEntityName = 'Name of the Federal Entity',
  colonyName = 'Name of the Colony',
}

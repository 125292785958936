import { Alert, message, Typography } from 'antd';
import { useResetPasswordMutation } from 'features/auth/api';
import React, { useEffect, useState } from 'react';
import { Logo } from 'shared/assets/icons';
import { Button } from 'shared/components/button';
import { CustomLink } from 'shared/components/customLink';
import { ErrorMessage } from 'shared/components/errorMessage';
import { ErrorVerify } from 'shared/components/errorVerify';
import { Loading } from 'shared/components/loading';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { USER_EMAIL } from 'shared/constants';
import { getLocalStorage } from 'shared/libs/storageService/localStorage';

const ResetPasswordVerify = () => {
  const [email, setEmail] = useState('');
  const [fetchResetPassword, { isLoading, isSuccess, isError }] =
    useResetPasswordMutation();

  useEffect(() => {
    const email = getLocalStorage(USER_EMAIL);
    setEmail(email);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      message.success(
        `Email to complete reset-password was send to email ${email}. Check your email.`
      );
    }
  }, [isSuccess]);

  const resendEmail = async () => {
    await fetchResetPassword(email);
  };
  return (
    <div className="center-page flex-column">
      {!isError && (
        <>
          <div className="logo-register">
            <Logo />
          </div>

          <Typography.Paragraph className="extrabold-24 text-center">
            {`Email with password reset link was send to ${email || ''}.`}
          </Typography.Paragraph>
          <Typography.Paragraph className="extrabold-24 text-center">
            Check your mailbox.
          </Typography.Paragraph>

          <Button
            onClick={resendEmail}
            disabled={isLoading}
            className="mt-16 flex-row"
          >
            Request Reset
            {isLoading && <Loading className="ml-12 spin-white" size="small" />}
          </Button>
          <CustomLink className="mt-16" to={RoutePath['/login']}>
            Login
          </CustomLink>
        </>
      )}

      {isError && <ErrorVerify />}
    </div>
  );
};

export default ResetPasswordVerify;

import dayjs from 'dayjs';

export const deepCompare = (obj1: any, obj2: any) => {
  let hasChanges = false;
  const changes: any = {};

  for (let key in obj1) {
    if (key === 'birthDate') {
      if (!dayjs(obj1[key]).isSame(dayjs(obj2[key]), 'day')) {
        changes[key] = obj1[key];
        hasChanges = true;
      }
    } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      const nestedChanges = deepCompare(obj1[key], obj2[key]);
      if (nestedChanges.hasChanges) {
        changes[key] = nestedChanges.changes;
        hasChanges = true;
      }
    } else if (obj1[key] !== obj2[key]) {
      changes[key] = obj1[key];
      hasChanges = true;
    }
  }

  return { hasChanges, changes };
};

import { APP_BASE_PATH } from 'shared/constants';
import { setCookie, getCookie, removeCookie } from './cookie';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from './localStorage';
import { getDomainName } from '../helpers';

const APP_URL_COOKIE = APP_BASE_PATH
  ? `.${getDomainName(APP_BASE_PATH)}`
  : 'localhost';

export const setStorageData = <
  T extends Record<string, unknown> | string | object,
>(
  storage: 'cookie' | 'local',
  key: string,
  data: T,
  options?: object
) => {
  if (options === undefined) {
    options = { domain: APP_URL_COOKIE };
  }
  switch (storage) {
    case 'cookie':
      return setCookie(key, data, options);
    case 'local':
      return setLocalStorage(key, data);
  }
};
export const getStorageData = (storage: 'cookie' | 'local', key: string) => {
  switch (storage) {
    case 'cookie':
      return getCookie(key);
    case 'local':
      return getLocalStorage(key);
  }
};
export const removeStorageData = (
  storage: 'cookie' | 'local',
  key: string,
  options?: object
) => {
  if (options === undefined) {
    options = { domain: APP_URL_COOKIE };
  }
  switch (storage) {
    case 'cookie':
      return removeCookie(key, options);
    case 'local':
      return removeLocalStorage(key);
  }
};

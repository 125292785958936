import { Typography } from 'antd';
import { TypographyProps } from 'antd/es/typography/Typography';
import React from 'react';

export const ErrorMessage: React.FC<TypographyProps<'span'>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Typography.Text type="danger" className={className} {...props}>
      {' '}
      {children}
    </Typography.Text>
  );
};

import { passwordRegex } from 'shared/regExpText';

export const validatePassword = (
  value: string,
  getFieldValue: (field: string) => string,
  fieldName?: string
) => {
  const minLength = 8;
  const maxLength = 32;

  if (!value || value.length < minLength || value.length > maxLength) {
    return Promise.reject(
      new Error(
        ` ${fieldName ? fieldName : 'Password'} must be between ${minLength} and ${maxLength} characters.`
      )
    );
  }

  if (!passwordRegex.test(value)) {
    return Promise.reject(
      new Error(
        `${fieldName ? fieldName : 'Password'} should include Unicode latin letter, numbers, special symbols: ~\`!@#$%^&*()_-+= {[}]|\\:;"'<,>.?/ allowed only. Should contain at least one capital letter, one small letter, special char, number.`
      )
    );
  }

  return Promise.resolve();
};

export const validateOldPassword = (
  value: string,
  getFieldValue: (field: string) => string,
  fieldName?: string
) => {
  const minLength = 8;
  const maxLength = 32;

  if (!value || value.length < minLength || value.length > maxLength) {
    return Promise.reject(
      new Error(
        ` ${fieldName ? fieldName : 'Password'} must be between ${minLength} and ${maxLength} characters.`
      )
    );
  }

  return Promise.resolve();
};

export const validatePasswordConfirm = (
  value: string,
  getFieldValue: (field: string) => string,
  isReset?: boolean
) => {
  const fieldName = isReset ? 'newPassword' : 'password';
  if (!value || getFieldValue(fieldName) === value) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`Your passwords do not match`));
};

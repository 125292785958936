import React, { useEffect, useMemo, useState } from 'react';
import { IRegisterForm } from '../types/registerFormTypes';
import { Form, Input } from 'antd';
import { CustomLabel } from 'shared/components/customLabel';
import { Button } from 'shared/components/button';
import { CustomLink } from 'shared/components/customLink';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { validatePassword, validatePasswordConfirm } from 'shared/libs/helpers';
import { useRegisterUserMutation } from 'features/auth/api';
import { Loading } from 'shared/components/loading';
import { setLocalStorage } from 'shared/libs/storageService/localStorage';
import { useNavigate } from 'react-router-dom';
import { PasswordInfoText, USER_EMAIL } from 'shared/constants';
const minLengthPassword = 8;
const minLengthEmail = 2;
const RegisterForm = () => {
  const [form] = Form.useForm<IRegisterForm>();
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [fetchUserRegister, { isLoading, error, data, isSuccess }] =
    useRegisterUserMutation();

  useEffect(() => {
    if (isSuccess) {
      setLocalStorage(USER_EMAIL, form.getFieldValue('email'));
      navigate(RoutePath['/register/verify']);
      form.resetFields();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      form.setFields([
        {
          name: 'email',
          errors: ['Invalid email.'],
        },
      ]);
    }
  }, [error]);

  const handleSubmit = async (value: IRegisterForm) => {
    try {
      await validatePassword(value.password, form.getFieldValue);
      await fetchUserRegister({
        email: value.email,
        password: value.password,
        password_confirmation: value.confirmPassword,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleFormValuesChange = () => {
    const values = form.getFieldsValue();
    const allFieldsFilled = Object.values(values).every((value) => value);
    const isMinLengthEmail = values.email?.length >= minLengthEmail;
    const isMinLengthPassword = values.password?.length >= minLengthPassword;
    const isMinLengthConfirmPassword =
      values.confirmPassword?.length >= minLengthPassword;

    setIsButtonDisabled(
      !allFieldsFilled ||
        !isMinLengthPassword ||
        !isMinLengthConfirmPassword ||
        !isMinLengthEmail
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ maxWidth: '400px', width: '100%' }}
      initialValues={{}}
      onFinish={handleSubmit}
      onValuesChange={handleFormValuesChange}
    >
      <Form.Item
        label={<CustomLabel text="Email" />}
        name="email"
        rules={[
          { required: true, message: 'Please input your email!' },
          { type: 'email', message: 'Not an email' },
        ]}
        validateTrigger="onSubmit"
      >
        <Input type="text" className="input" size="large" />
      </Form.Item>

      <Form.Item
        label={<CustomLabel text="Password" />}
        name="password"
        rules={[
          { required: true, message: 'Please input your password!' },
          ({ getFieldValue }) => ({
            validator(_: any, value: string) {
              return validatePassword(value, getFieldValue)
                .then(() => {
                  setIsPasswordError(false);
                })
                .catch((error) => {
                  setIsPasswordError(true);
                  return Promise.reject(error);
                });
            },
          }),
        ]}
        validateTrigger="onSubmit"
      >
        <Input.Password size="large" />
      </Form.Item>
      {!isPasswordError && !error && (
        <span
          style={{ display: 'block', marginTop: '-20px' }}
          dangerouslySetInnerHTML={{
            __html: PasswordInfoText,
          }}
        />
      )}
      <Form.Item
        label={<CustomLabel text="Confirm Password" />}
        name="confirmPassword"
        rules={[
          { required: true, message: 'Please input your password!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return validatePasswordConfirm(value, getFieldValue);
            },
          }),
        ]}
        validateTrigger="onSubmit"
        className="mt-20"
      >
        <Input.Password size="large" />
      </Form.Item>

      <div className="buttons-wrapper mt-16">
        <Button
          type="submit"
          className="flex-row mb-24"
          disabled={isLoading || isButtonDisabled}
        >
          Register
          {isLoading && <Loading className="ml-12 spin-white" size="small" />}
        </Button>
        <CustomLink to={RoutePath['/login']}>Login</CustomLink>
      </div>
    </Form>
  );
};
export default RegisterForm;

import { InvoicesStatus } from 'features/invoices/types/invoicesTypes';

export enum INVOICES_STATUS {
  'draft' = 'Draft',
  'paused' = 'Paused',
  'submitted' = 'Submitted',
  'in_processing' = 'In Processing',
  'processed' = 'Processed',
  'submitting_error' = 'Submitting Error',
}

export interface DropDownMenu {
  label: string | React.ReactElement;
  key: string;
  disabled?: boolean;
  onClick: () => void;
}

export interface TableData {
  id: number;
  company: string;
  created: string;
  submitted?: string;
  status?: InvoicesStatus;
  dateProcessed?: string;
  facturaUrl?: string | null;
}

export interface InvoicesTableData {
  id: number;
  company: string;
  created: string;
  total_amount: string;
  receipt_number: string;
  isShowWarning?: boolean;

  onCell: (record: TableData) => void;
}

export interface InvoicesSubmittedTableData extends InvoicesTableData {
  submitted: string;
  status: InvoicesStatus;
  dateProcessed: string;
  facturaUrl: string | null;
}

export interface SelectOptions {
  value: string;
  label: string;
}

export interface GroupedSelectCodesOptions {
  personal: SelectOptions[];
  company: SelectOptions[];
}

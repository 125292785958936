import { profileApi } from './profileApi';
import profileReducer from './profileSlice';

const {
  useCreateProfileMutation,
  useGetProfileQuery,
  useGetProfileCodesQuery,
  useLazyGetProfileCodesQuery,
  useLazyGetProfileQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
} = profileApi;

export {
  profileApi,
  profileReducer,
  useCreateProfileMutation,
  useGetProfileQuery,
  useGetProfileCodesQuery,
  useLazyGetProfileCodesQuery,
  useLazyGetProfileQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
};

import React from 'react';
import { Outlet } from 'react-router-dom';
import './appLayout.scss';
import { Navigate } from 'widgets/Navigate/ui';
import { withAuth } from 'shared/hocs/withAuth';
import { Footer } from 'shared/components/footer';

const AppLayout = () => {
  return (
    <>
      <Navigate />
      <div className="pageWrapper">
        <div className="pageContainer">
          <div className="pageContent">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withAuth(AppLayout);

import { message } from 'antd';
import { useLazyGetInvoicesSavedQuery } from 'features/invoices/api';
import React, { useEffect, useMemo, useState } from 'react';
import { InvoicesSubmittedTableData, TableData } from 'shared/types/invoices';
import { formatDateToString } from 'shared/libs/helpers/helperFunctions/helperFunctions';
import { useInvoiceSubmittedTableConfig } from './hook/useInvoiceSubmittedTableConfig';
import './styles/invoices-submitted.scss';
import { InvoicesTable } from 'widgets/InvoicesTable';
import { InvoiceResponse } from 'features/invoices/types/invoicesTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { useSort } from './hook/useSort';
import { useFilter } from './hook/useFilter';
import { FiltersForm } from 'widgets/FiltersForm';
import dayjs, { Dayjs } from 'dayjs';
import { getDateRange } from 'shared/libs/helpers/helperFunctions/dateRangeParser';
import { DateRange } from 'shared/types/shared';
import { downloadFile, formatValueToСurrency } from 'shared/libs/helpers';

const InvoicesSubmitted = () => {
  const [fetchInvoices] = useLazyGetInvoicesSavedQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [submittedInvoice, setSubmittedInvoice] =
    useState<InvoiceResponse | null>(null);
  const [emptyTableText, setEmptyTableText] = useState(
    'No submitted invoices yet. Create an send an Invoice.'
  );

  const navigate = useNavigate();
  const location = useLocation();

  const {
    companiesFilter,
    dateCreatedFilter,
    statusFilter,
    processedFilter,
    handleClearAll,
    handleFiltersApply,
  } = useFilter({ setCurrentPage });
  const { handleSort, sortKey, sortOrder } = useSort();

  const fetchSubmittedInvoices = async (page: number, pageSize: number) => {
    try {
      let dateCreatedFrom: Dayjs | null = null;
      let dateCreatedTo: Dayjs | null = null;
      let dateProcessedFrom: Dayjs | null = null;
      let dateProcessedTo: Dayjs | null = null;

      const searchParams = new URLSearchParams(location.search);
      const sortParam = searchParams.get('sort');
      const companiesParam = searchParams.get('companies');
      const dateCreatedParam = searchParams.get('date_created');
      const statusParam = searchParams.get('status');
      const processedParam = searchParams.get('processed');

      if (dateCreatedParam !== null && dateCreatedParam !== 'full_period') {
        const { from, to } = getDateRange(dateCreatedParam as DateRange);
        dateCreatedFrom = from;
        dateCreatedTo = to;
      }
      if (processedParam !== null && processedParam !== 'full_period') {
        const { from, to } = getDateRange(processedParam as DateRange);
        dateProcessedFrom = from;
        dateProcessedTo = to;
      }
      const searchUrl = `${sortParam ? `&ordering=${sortParam}` : `&ordering=-submission_date`}${companiesParam ? `&company=${companiesParam}` : ''}${dateCreatedFrom && dateCreatedTo ? `&created_after=${dayjs(dateCreatedFrom).format('YYYY-MM-DD')}&created_before=${dayjs(dateCreatedTo).format('YYYY-MM-DD')}` : ''}${dateProcessedFrom && dateProcessedTo ? `&processed_after=${dayjs(dateProcessedFrom).format('YYYY-MM-DD')}&processed_before=${dayjs(dateProcessedTo).format('YYYY-MM-DD')}` : ''}`;
      const status =
        (statusParam !== 'all' && statusParam) ||
        'paused,processed,in_processing,submitting_error';
      setIsLoading(true);
      const data = await fetchInvoices({
        status: status,
        pageNumber: page,
        pageSize: pageSize,
        searchParams: searchUrl,
      }).unwrap();
      if (data) {
        const filteredInvoices = data.results.filter(
          (item) => item.status !== ('Draft' as 'draft')
        );
        if (
          (!filteredInvoices.length && searchUrl) ||
          (!filteredInvoices.length && statusParam)
        ) {
          setEmptyTableText('Nothing was found');
        }
        setSubmittedInvoice({ ...data, results: filteredInvoices });
      }
    } catch (e) {
      message.error('Failed to fetch Invoices');
      console.error('Failed to fetch Invoices', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubmittedInvoices(currentPage, pageSize);
  }, [currentPage, pageSize, location.search]);

  const handleViewInvoice = (record: TableData) => {
    handleRowClick(record);
  };

  const handlePrintInvoice = (record: TableData) => {
    if (!record.facturaUrl) return;
    const fileType = record.facturaUrl.split('.');
    downloadFile(record.facturaUrl, `factura.${fileType[fileType.length - 1]}`);
  };

  const handleRowClick = (record: TableData) => {
    const recordId = record.id;
    navigate(RoutePath['/invoice/:id'].replace(':id', recordId.toString()), {
      state: { from: location.pathname },
    });
  };

  const { tableColumns } = useInvoiceSubmittedTableConfig({
    handleViewInvoice,
    handlePrintInvoice,
    handleRowClick,
    handleSort,
    sortKey,
    sortOrder,
  });

  const dataToRender = useMemo((): InvoicesSubmittedTableData[] => {
    if (!submittedInvoice?.results.length) {
      return [];
    }
    return submittedInvoice.results.map((invoice) => ({
      company: invoice.company,
      created: formatDateToString(invoice.created),
      submitted: invoice.submission_date
        ? formatDateToString(invoice.submission_date)
        : '',
      status: invoice.status,
      dateProcessed: invoice.processing_date
        ? formatDateToString(invoice.processing_date)
        : '',
      receipt_number: invoice.receipt_number || '',
      total_amount: formatValueToСurrency(invoice.total_amount || ''),
      id: invoice.id,
      facturaUrl: invoice.factura_url,
      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
    }));
  }, [submittedInvoice]);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div className="invoices-table">
      <div className="invoice-filter-block">
        <FiltersForm
          dataCreatedInit={dateCreatedFilter}
          handleFiltersApply={handleFiltersApply}
          handleClearAll={handleClearAll}
          companiesInit={companiesFilter}
          statusFilter={statusFilter}
          processedFilter={processedFilter}
          filters={['dateCreated', 'processed', 'status', 'companies']}
        />
      </div>
      <InvoicesTable
        tableColumns={tableColumns}
        dataToRender={dataToRender}
        emptyTextRender={emptyTableText}
        invoicesType="submitted"
        onPageChange={handlePageChange}
        totalInvoices={submittedInvoice?.count || 0}
        isLoadingTable={isLoading}
        pageSize={pageSize}
        currentPage={currentPage}
      />
    </div>
  );
};

export default InvoicesSubmitted;

import React from 'react';
import { ProfileForm } from 'widgets/ProfileForm';
import './styles/profile.scss';
import { useCreateProfileMutation } from 'features/profile/api';
import { IProfileData } from 'shared/types/profile';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
const ProfileCreate = () => {
  const [fetchCreateProfile, { isLoading }] = useCreateProfileMutation();
  const navigate = useNavigate();
  const handleCreateProfile = async (value: IProfileData) => {
    try {
      await fetchCreateProfile(value).unwrap();
      navigate(RoutePath['/invoices/saved']);
    } catch (error) {
      message.error('something vent wrong');
    }
  };
  return (
    <div className="pageContent center-page">
      <h2 className="header-text profile-title">
        Create Profile
        <span>
          Please complete your profile details exactly as it appears within your
          SAT account.
        </span>
      </h2>

      <ProfileForm
        isLoading={isLoading}
        handleSubmit={handleCreateProfile}
        profile={null}
      />
    </div>
  );
};

export default ProfileCreate;

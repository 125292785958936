import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IFilterForm } from 'widgets/FiltersForm/types/filterFormTypes';

export const useFilter = ({
  setCurrentPage,
}: {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [companiesFilter, setCompaniesFilter] = useState<string[]>([]);
  const [dateCreatedFilter, setDateCreateFilter] =
    useState<IFilterForm['dateCreated']>(null);

  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    const sortParam = currentParams.get('sort');

    const companiesParam = currentParams.get('companies');
    const dateCreatedParam = currentParams.get('date_created');

    handleFiltersApply(
      {
        companies: companiesParam?.length ? companiesParam?.split(',') : [],
        dateCreated: dateCreatedParam
          ? (dateCreatedParam as IFilterForm['dateCreated'])
          : null,
      },
      sortParam || ''
    );
  }, []);

  const handleFiltersApply = (values: IFilterForm, sortParams?: string) => {
    const searchParams = new URLSearchParams(location.search);

    if (values.companies.length) {
      searchParams.set('companies', values.companies.join(','));
      setCompaniesFilter(values.companies);
    } else {
      searchParams.delete('companies');
      setCompaniesFilter([]);
    }

    if (values.dateCreated) {
      searchParams.set('date_created', values.dateCreated);
      setDateCreateFilter(values.dateCreated);
    } else {
      searchParams.delete('date_created');
    }
    setCurrentPage(1);
    navigate({ search: searchParams.toString() });
  };
  const handleClearAll = () => {
    setCompaniesFilter([]);
    setDateCreateFilter(null);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('date_created');
    searchParams.delete('companies');
    setCurrentPage(1);
    navigate({ search: searchParams.toString() });
  };
  return {
    handleFiltersApply,
    handleClearAll,
    dateCreatedFilter,
    companiesFilter,
  };
};

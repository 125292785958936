import { TableProps, Typography } from 'antd';
import { useState } from 'react';
import { DropDownInvoice } from 'shared/components/dropDown/ui/DropDownInvoices';
import { WarningOutlined } from '@ant-design/icons';

import '../styles/invoices-saved.scss';

import {
  DropDownMenu,
  InvoicesTableData,
  TableData,
} from 'shared/types/invoices';
import { SortedKey } from '../types/sortTypes';
import { CaretDownOutlined, CaretUpOutlined } from 'shared/assets/icons';

interface UseInvoiceTableConfigProps {
  handleViewInvoice: (record: TableData) => void;
  handleEditInvoice: (record: TableData) => void;
  submitInvoice: (record: TableData) => void;
  handleRowClick: (record: TableData) => void;
  handleSort: (key: SortedKey, order: 'asc' | 'desc') => void;
  sortKey: SortedKey;
  sortOrder: 'asc' | 'desc';
}

type SetOpenKeys = (key: number | null) => void;

export const useInvoiceTableConfig = ({
  handleViewInvoice,
  handleEditInvoice,
  submitInvoice,
  handleRowClick,
  handleSort,
  sortKey,
  sortOrder,
}: UseInvoiceTableConfigProps) => {
  const [openKeys, setOpenKeys] = useState<number | null>(null);

  const getMenuItems = (
    record: TableData,
    setOpenKeys: SetOpenKeys
  ): DropDownMenu[] => [
    {
      label: <Typography className="">Open Invoice</Typography>,
      key: 'view',
      onClick: () => {
        handleViewInvoice(record);
        setOpenKeys(null);
      },
    },
    {
      label: <Typography className="">Edit Invoice</Typography>,
      key: 'edit',
      onClick: () => {
        handleEditInvoice(record);
        setOpenKeys(null);
      },
    },
    {
      label: <Typography className="">Submit Invoice</Typography>,
      key: 'submit',
      onClick: () => {
        submitInvoice(record);
        setOpenKeys(null);
      },
    },
  ];

  const tableColumns: TableProps<InvoicesTableData>['columns'] = [
    {
      title: (
        <div className="table-title">
          Id
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'id' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('id', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'id' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('id', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: ['id'],
      key: 'id',
      align: 'center',
      width: '10%',
      ellipsis: true,
      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: (
        <div className="table-title">
          Company
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'company_config__company__name' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('company_config__company__name', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'company_config__company__name' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() =>
                handleSort('company_config__company__name', 'desc')
              }
            />
          </span>
        </div>
      ),
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      width: '20%',
      ellipsis: false,
      onCell: (record: TableData) => ({
        onClick: () => handleRowClick(record),
      }),
      render: (text, record) => (
        <div>
          {text}
          {record.isShowWarning && (
            <WarningOutlined style={{ color: '#DC1732', marginLeft: 8 }} />
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="table-title">
          Date Created
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'created' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('created', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'created' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('created', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: 'created',
      align: 'center',
      key: 'created',
      width: '30%',
      onCell: (record: TableData) => ({
        onClick: () => {
          handleRowClick(record);
        },
      }),
      render: (created: string) => <span>{created}</span>,
    },

    {
      title: (
        <div className="table-title">
          Total Amount
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'total_amount' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('total_amount', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'total_amount' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('total_amount', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: 'total_amount',
      align: 'center',
      key: 'total_amount',
      width: '20%',
      onCell: (record: TableData) => ({
        onClick: () => {
          handleRowClick(record);
        },
      }),
      render: (totalAmount: string) => <span>{totalAmount || '---'}</span>,
    },
    {
      title: (
        <div className="table-title">
          Receipt Number
          <span className="sort-icons">
            <CaretUpOutlined
              className={`caret ${sortKey === 'receipt_number' && sortOrder === 'asc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('receipt_number', 'asc')}
            />
            <CaretDownOutlined
              className={`caret ${sortKey === 'receipt_number' && sortOrder === 'desc' ? 'caret-active' : ''}`}
              onClick={() => handleSort('receipt_number', 'desc')}
            />
          </span>
        </div>
      ),
      dataIndex: 'receipt_number',
      align: 'center',
      key: 'receipt_number',
      width: '20%',
      onCell: (record: TableData) => ({
        onClick: () => {
          handleRowClick(record);
        },
      }),
      render: (receiptNumber: string) => <span>{receiptNumber || '---'}</span>,
    },

    {
      title: 'Menu',
      key: 'menu',
      width: '10%',
      align: 'center',
      render: (record: TableData) => (
        <DropDownInvoice
          menuItems={getMenuItems(record, setOpenKeys)}
          companyId={record.id}
          setOpenKeys={setOpenKeys}
          openKeys={openKeys}
        />
      ),
    },
  ];

  return {
    tableColumns,
  };
};

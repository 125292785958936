import { CfdiItem, IProfilesCode } from './../types/profileTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfile } from '../types/profileTypes';

interface ProfileState {
  profile: IProfile | null;
  codes: CfdiItem[];
}

const initialState: ProfileState = {
  profile: null,
  codes: [],
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<IProfile | null>) {
      state.profile = action.payload;
    },
    setCodes(state, action: PayloadAction<IProfilesCode>) {
      const allValues = Object.values(action.payload)
        .flatMap((category) => Object.values(category))
        .flat();
      state.codes = (allValues as CfdiItem[]) || [];
    },
  },
});

export default profileSlice.reducer;

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DateRange } from 'shared/types/shared';

dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrAfter);

export const getDateRange = (key: DateRange) => {
  const now = dayjs();
  let from: dayjs.Dayjs | null = null;
  let to: dayjs.Dayjs | null = null;

  switch (key) {
    case 'full_period':
      from = null;
      to = null;
      break;
    case 'last_day':
      from = now.subtract(1, 'day');
      to = now.endOf('day');
      break;
    case 'last_week':
      from = now.subtract(7, 'day');
      to = now.endOf('day');
      break;
    case 'last_month':
      from = now.subtract(1, 'month');
      to = now.endOf('day');
      break;
    case 'last_quarter':
      from = now.subtract(1, 'quarter');
      to = now.endOf('day');
      break;
    case 'last_half_a_year':
      from = now.subtract(6, 'month');
      to = now.endOf('day');
      break;
    case 'last_year':
      from = now.subtract(1, 'year');
      to = now.endOf('day');
      break;
    default:
      from = null;
      to = null;
      break;
  }

  return { from, to };
};

import React, { useEffect, useState } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import '../styles/maskedNumberInput.scss';

interface MaskedNumberInputProps {
  initialValue?: string;
  onChange?: (value: string) => void;
}

export const MaskedNumberInput: React.FC<MaskedNumberInputProps> = ({
  initialValue,
  onChange,
}) => {
  const [value, setValue] = useState<string>(initialValue || '');

  useEffect(() => {
    if (initialValue !== undefined) {
      const formattedValue = formatValue(initialValue);
      setValue(formattedValue);
    }
  }, [initialValue]);

  const numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 8,
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = formatValue(rawValue);
    setValue(formattedValue);
    if (onChange) {
      onChange(formattedValue);
    }
  };

  const formatValue = (value: string): string => {
    const [integerPart, decimalPart] = value.split('.');
    return decimalPart === undefined || decimalPart === ''
      ? `${integerPart}.00`
      : value;
  };
  return (
    <MaskedInput
      className="input input-mask-number"
      mask={numberMask}
      value={value}
      onChange={handleChange}
      guide={false}
      placeholder="0.00"
    />
  );
};

import React from 'react';

const AboutUs = () => {
  return (
    <div className="w-100 lh-15">
      <h2 className="mb-16">About Us</h2>
      <p>
        We at Factura Mas Rapido are an Artificial Intelligence technology based
        company who specialise in finance and tax focussed software innovations.
        We aim to break the mould and typical normalities by bringing new,
        exciting and practical ideas to the market.
        <br />
        <br />
      </p>
      <p>
        We are committed to being a responsible business – in how we operate,
        serve society, respect our people and the environment.
        <br />
        <br />
      </p>
      <p>
        Our holistic approach to cutting-edge technologies ensures we are
        providing significant transformational benefits and insights to all
        organisations, client partners and individual users.
        <br />
        <br />
      </p>
      <p>
        Our ethos and cultural values which drives us each day can be defined as
        “the continued advancement in technological innovations and solutions –
        for the betterment of people’s lives, so you have more time to focus on
        what you do best.”{' '}
      </p>
      <br />
      <br />
      <br />
    </div>
  );
};

export default AboutUs;

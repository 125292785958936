import { Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/components/button';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';

const SuccessChangePassword = () => {
  const navigate = useNavigate();
  const navigateToProfile = () => {
    navigate(RoutePath['/profile']);
  };
  return (
    <div className="center-page flex-column">
      <>
        <Typography className="extrabold-24 text-center">
          Password was successfully changed.
        </Typography>

        <Typography className="normal-16 mt-16">
          New login requires new password
        </Typography>

        <Button
          className="mt-16 flex-center flex-row"
          onClick={navigateToProfile}
        >
          Back to Profile
        </Button>
      </>
    </div>
  );
};

export default SuccessChangePassword;

import { useAppSelector } from 'app/providers/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Profile } from 'widgets/Profile';

const ProfilePage = () => {
  const { profile } = useAppSelector((state) => state.profileReducer);
  const navigate = useNavigate();
  const location = useLocation();

  const handleEdit = () => {
    navigate(RoutePath['/profile/edit']);
  };
  const handleBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(RoutePath['/invoices/saved']);
    }
  };
  return (
    <div className="pageContent center-page">
      {profile?.user.id && (
        <Profile
          profile={profile}
          handleEditProfile={handleEdit}
          handleBack={handleBack}
        />
      )}
    </div>
  );
};

export default ProfilePage;

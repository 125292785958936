import React, { ButtonHTMLAttributes } from 'react';

import './button.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  disabled,
  className,
  children,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`button ${className || ''}`}
      {...rest}
    >
      {children}
    </button>
  );
};

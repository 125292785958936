import { useAppDispatch, useAppSelector } from 'app/providers/store';
import { useLazyGetProfileQuery } from 'features/profile/api';
import { profileSlice } from 'features/profile/api/profileSlice';
import { IProfile } from 'features/profile/types/profileTypes';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from 'shared/components/loading';
import {
  AppRoutes,
  RegisterRoutes,
  RoutePath,
} from 'shared/config/routerConfig/routerConfig';
import { ACCESS_TOKEN } from 'shared/constants';
import { getStorageData } from 'shared/libs/storageService';

export const withAuth = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const accessToken = getStorageData('cookie', ACCESS_TOKEN);
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((state) => state.profileReducer);
    const { setProfile } = profileSlice.actions;

    const [hasWindow, setHasWindow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isCheckedRules, setIsCheckedRules] = useState(false);
    const [isFetchProfile, setIsFetchProfile] = useState(false);
    const [fetchProfile, { isLoading, error }] = useLazyGetProfileQuery();
    const registerPaths = useMemo(
      () => Object.values(RegisterRoutes) as string[],
      []
    );
    const isProfileNeededCreate = useMemo(() => {
      return (
        accessToken &&
        !profile &&
        !isLoading &&
        !registerPaths.includes(location.pathname) &&
        ![AppRoutes.PROFILE_CREATE as string].includes(location.pathname)
      );
    }, [accessToken, profile, isLoading, location.pathname, registerPaths]);

    useEffect(() => {
      setHasWindow(true);
    }, []);

    const getProfile = async () => {
      try {
        const profileData = await fetchProfile({}).unwrap();
        if (profileData) {
          dispatch(setProfile(profileData));
        }
      } catch (e) {
        dispatch(setProfile(null));
        console.error(e, 'get profile failed');
      } finally {
        setIsFetchProfile(false);
      }
    };
    useEffect(() => {
      if (!accessToken) {
        dispatch(setProfile(null));
        return;
      }
      if (accessToken && !profile && !isLoading) {
        setIsFetchProfile(true);
        getProfile();
      }
    }, [accessToken, profile, isLoading, location.pathname]);

    useEffect(() => {
      if (!hasWindow || isLoading || isFetchProfile) return;

      if (!accessToken && !registerPaths.includes(location.pathname)) {
        setIsCheckedRules(true);
        navigate(RoutePath['/login']);
      } else if (accessToken && registerPaths.includes(location.pathname)) {
        setIsCheckedRules(true);
        navigate(RoutePath['/invoices/saved']);
      } else if (isProfileNeededCreate) {
        setIsCheckedRules(true);
        navigate(RoutePath['/profile-create']);
      } else if (
        accessToken &&
        profile?.name &&
        !registerPaths.includes(location.pathname) &&
        !profile?.isOnboardingPassed
      ) {
        setIsCheckedRules(true);
        navigate(RoutePath['/onboarding']);
      } else if (
        profile?.name &&
        (location.pathname === RoutePath['/profile-create'] ||
          location.pathname === RoutePath['/onboarding'])
      ) {
        setIsCheckedRules(true);
        navigate(RoutePath['/invoices/saved']);
      } else {
        setIsCheckedRules(true);
      }
    }, [
      hasWindow,
      isFetchProfile,
      isLoading,
      accessToken,
      profile,
      isProfileNeededCreate,
      location.pathname,
      registerPaths,
      navigate,
    ]);
    if (!hasWindow || isLoading || !isCheckedRules) {
      return (
        <div className="center-page">
          <Loading size="large" />
        </div>
      );
    } else {
      return <WrappedComponent {...props} />;
    }
  };
};

import React from 'react';
import { ClientRoutes } from './providers/router';
import { StoreProvider } from './providers/store';

function App() {
  return (
    <StoreProvider>
      <ClientRoutes />
    </StoreProvider>
  );
}

export default App;

import React, { useEffect, useState } from 'react';

import '../styles/invoiceInfo.scss';
import { IInvoiceById, IOptions } from 'features/invoices/types/invoicesTypes';
import { CfdiItem } from 'features/profile/types/profileTypes';
import { INVOICES_STATUS } from 'shared/types/invoices';
import { Button } from 'shared/components/button';
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import {
  parseProfileCodes,
  formatValueToСurrency,
} from 'shared/libs/helpers/helperFunctions/helperFunctions';
import { Loading } from 'shared/components/loading';
interface IInvoiceInfoProps {
  invoice: IInvoiceById | null;
  codes: CfdiItem[];
  isDownloadFactura: boolean;
  handleEdit: (id: number) => void;
  handleSubmit: (id: number) => void;
  handlePrintInvoice?: () => void;
}

export const InvoiceInfo: React.FC<IInvoiceInfoProps> = ({
  codes,
  invoice,
  isDownloadFactura,
  handleEdit,
  handleSubmit,
  handlePrintInvoice,
}) => {
  const [invoicesToRender, setInvoicesToRender] = useState<
    IInvoiceById['actions'] | null
  >(null);
  useEffect(() => {
    const configOnlyFields = invoice?.actions?.filter(
      (config) =>
        config.field_type === 'text' ||
        config.field_type === 'select' ||
        config.field_type === 'radio' ||
        config.field_type === 'date' ||
        config.field_type === 'checkbox' ||
        config.field_type === 'number'
    );
    const configOnlyInvoiceSource = configOnlyFields?.filter(
      (item) => item.source === 'invoice'
    );
    const taxRegimeField =
      invoice?.actions?.filter((action) =>
        action.field_name.includes('tax_regime')
      ) || [];
    const cfdiField =
      invoice?.actions?.filter((action) =>
        action.field_name.some((item) => item === 'cfdi')
      ) || [];

    configOnlyInvoiceSource &&
      setInvoicesToRender([
        ...configOnlyInvoiceSource,
        ...taxRegimeField,
        ...cfdiField,
      ]);
  }, [invoice]);

  const renderValue = ({
    fieldType,
    value,
    options,
    fieldName,
  }: {
    fieldType: string;
    value: string;
    options: IOptions[];
    fieldName: string;
  }) => {
    switch (fieldType) {
      case 'number': {
        return formatValueToСurrency(value);
      }
      case 'date': {
        // return moment(value).format('YYYY-MM-DD');
        return value;
      }

      case 'radio':
      case 'select': {
        if (
          fieldType === 'select' &&
          (fieldName === 'cfdi' || fieldName === 'tax_regime')
        ) {
          return parseProfileCodes({ value: value, codes: codes });
        }
        return options.length
          ? options.find((item) => item.value === value)?.label
          : value;
      }
      case 'checkbox': {
        return value ? (
          <CheckOutlined className="color-submitted icon-bold" />
        ) : (
          <MinusOutlined className="color-error icon-bold" />
        );
      }
      default:
        return parseProfileCodes({ value: value, codes: codes });
    }
  };
  return (
    <div className="invoice-info">
      <h3 className="invoice-info-title">
        Invoice Details{' '}
        {invoice && invoice.status !== 'draft' && (
          <span
            className={`invoice-info-data invoice-info-data-${invoice.status}`}
          >
            {INVOICES_STATUS[invoice.status as keyof typeof INVOICES_STATUS]}
          </span>
        )}
      </h3>
      {invoicesToRender !== null && invoice !== null && (
        <div>
          <div>
            <h3 className="invoice-info-company">{invoice.company_name}</h3>
          </div>
          <div className="invoice-info-list-container">
            <ul className="invoice-info-list">
              {invoicesToRender.map((item, idx) => (
                <li key={`${item.id}-${idx}`}>
                  <span className="invoice-info-label">
                    {item.verbose_name}:
                  </span>
                  <span className="invoice-info-data">
                    {renderValue({
                      fieldType: item.field_type,
                      value: item.value,
                      options: item.options,
                      fieldName: item.field_name[0],
                    }) || '---'}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="invoice-info-buttons">
            {invoice.status === 'draft' ? (
              <>
                <Button onClick={() => handleEdit(invoice.id)}>Edit</Button>

                <Button onClick={() => handleSubmit(invoice.id)}>Submit</Button>
              </>
            ) : (
              <Button
                onClick={handlePrintInvoice}
                disabled={!invoice.factura_url}
                className="flex-row"
              >
                Download Factura
                {isDownloadFactura && (
                  <Loading className="ml-12 spin-white" size="small" />
                )}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

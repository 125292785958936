import { useAppDispatch, useAppSelector } from 'app/providers/store';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { ProfileForm } from 'widgets/ProfileForm';
import '../styles/profileEdit.scss';
import {
  useLazyGetProfileQuery,
  useUpdateProfileMutation,
} from 'features/profile/api';
import { message } from 'antd';
import { profileSlice } from 'features/profile/api/profileSlice';
const ProfileEdit = () => {
  const { profile } = useAppSelector((state) => state.profileReducer);

  const [fetchProfile] = useLazyGetProfileQuery();

  const [updateProfile] = useUpdateProfileMutation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { setProfile } = profileSlice.actions;

  const handleEditProfile = async (value: any) => {
    try {
      setLoading(true);
      if (!profile?.user.id) return;
      const data = await updateProfile({
        data: value,
        userId: profile?.user.id,
      }).unwrap();
      const profileData = await fetchProfile({}).unwrap();
      if (profileData) {
        dispatch(setProfile(profileData));
      }

      navigate(RoutePath['/profile']);
    } catch (error) {
      message.error('something vent wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(RoutePath['/profile']);
  };

  return (
    <div className="edit-profile-page">
      <h2 className="header-text edit-profile-page-title">Edit Profile</h2>
      <ProfileForm
        isLoading={loading}
        handleSubmit={handleEditProfile}
        profile={profile}
        handleCancel={handleCancel}
      />
    </div>
  );
};

export default ProfileEdit;

import { Spin } from 'antd';
import React from 'react';

interface IProps {
  className?: string;
  size?: 'large' | 'default' | 'small';
  children?: React.ReactNode;
  spinning?: boolean;
}

export const Loading: React.FC<IProps> = ({
  className,
  size = 'default',
  children,
  spinning = true,
}) => {
  return (
    <Spin
      className={className ? className : ''}
      spinning={spinning}
      size={size}
    >
      {children}
    </Spin>
  );
};

import React from 'react';
import '../styles/contactUs.scss';
const ContactUs = () => {
  return (
    <div className="w-100 lh-15">
      <h2 className="mb-16">Contact Us</h2>

      <div className="contact-us-body">
        <div className="contact-us-body-text">
          <p>
            The new offices of Factura Mas Rapido are being renovated at this
            very moment within Polanco, Mexico City.
            <br />
            <br />
          </p>

          <p>
            The modern offices will provide a futuristic and inviting experience
            for all our team and clients.
            <br />
            <br />
          </p>

          <p>
            Full details of our address and customer support details will follow
            very soon.
          </p>
        </div>

        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d940.6273526863326!2d-99.19541142525281!3d19.433591733711232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sua!4v1725009575205!5m2!1sen!2sua"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;

import { IProfileData } from 'shared/types/profile';
import { transformCreateAccount } from '../transformers/accountApiTransformers';
import {
  IChangePassword,
  IProfile,
  IProfileOnboardingComplete,
  IProfileRequest,
  IProfileResponse,
  IProfilesCode,
  IProfileUpdate,
} from '../types/profileTypes';
import { baseQueryWithReauth } from './../../../shared/libs/tokenService/tokenService';
import { createApi } from '@reduxjs/toolkit/query/react';
import { transformAccountResponse } from 'shared/libs/transformers/profile';

const isOnboardingComplete = (
  data: IProfileData | IProfileOnboardingComplete
): data is IProfileOnboardingComplete => {
  return 'is_onboarding_passed' in data;
};

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Profile'],
  endpoints: (build) => ({
    createProfile: build.mutation<IProfileRequest, IProfileData>({
      query: (account) => ({
        url: '/profiles/',
        method: 'POST',
        body: transformCreateAccount(account),
      }),
    }),
    getProfile: build.query<IProfile, unknown>({
      query: () => ({
        url: '/profiles/me/',
        method: 'GET',
      }),
      providesTags: [{ type: 'Profile', id: 'LIST' }],
      transformResponse: (response: IProfileResponse) => {
        return transformAccountResponse(response);
      },
    }),
    getProfileCodes: build.query<IProfilesCode, unknown>({
      query: () => ({
        url: '/profiles/codes/',
        method: 'GET',
      }),
    }),
    updateProfile: build.mutation<unknown, IProfileUpdate>({
      query: ({ userId, data }) => {
        return {
          url: `/profiles/${userId}/`,
          method: 'PATCH',
          body: isOnboardingComplete(data)
            ? data
            : transformCreateAccount(data),
        };
      },
    }),
    changePassword: build.mutation<unknown, IChangePassword>({
      query: (data) => {
        return {
          url: `/profiles/password/change/`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
  }),
});

import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import './custom-link.scss';

interface CustomLinkProps extends LinkProps {
  state?: any;
}

export const CustomLink: React.FC<CustomLinkProps> = ({
  className,
  children,
  state,
  ...rest
}) => {
  return (
    <Link className={`${className || ''} custom-link`} state={state} {...rest}>
      {children}
    </Link>
  );
};

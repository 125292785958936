import React, { useEffect, useState } from 'react';
import { Table, TablePaginationConfig, TableProps } from 'antd';
import '../styles/invoicesTable.scss';
import {
  InvoicesSubmittedTableData,
  InvoicesTableData,
} from 'shared/types/invoices';

interface Props {
  tableColumns: TableProps<any>['columns'];
  dataToRender: InvoicesTableData[] | InvoicesSubmittedTableData[];
  emptyTextRender: string;
  invoicesType: 'draft' | 'submitted';
  totalInvoices?: number;
  onPageChange: (page: number, pageSize: number) => void;
  isLoadingTable: boolean;
  pageSize: number;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  currentPage: number;
}

export const InvoicesTable: React.FC<Props> = ({
  tableColumns,
  dataToRender,
  emptyTextRender,
  invoicesType,
  totalInvoices,
  onPageChange,
  isLoadingTable,
  pageSize,
  setSelectedRowKeys,
  selectedRowKeys,
  currentPage,
}) => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: pageSize,
    total: totalInvoices,
    // showSizeChanger: true,
    // pageSizeOptions: ['10', '20', '50', '100'],
  });

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: currentPage || 1,
      total: totalInvoices,
      pageSize: pageSize,
    }));
  }, [totalInvoices, currentPage]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys && setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
    onPageChange(pagination.current!, pagination.pageSize!);
  };
  return (
    <Table
      rowClassName="customTableRow"
      rowSelection={invoicesType === 'draft' ? rowSelection : undefined}
      columns={tableColumns}
      dataSource={dataToRender}
      rowKey={'id'}
      pagination={pagination}
      onChange={handleTableChange}
      // scroll={{ x: 'max-content' }}
      showSorterTooltip={false}
      tableLayout="auto"
      locale={{
        emptyText: <h3>{emptyTextRender}</h3>,
      }}
      style={{ width: '100%' }}
      loading={isLoadingTable}
    />
  );
};

import React from 'react';
import '../styles/dropDown.scss';
import { Dropdown } from 'antd';
import Icon from '@ant-design/icons';

import { DropDownIcon } from 'shared/assets/icons';
import { DropDownMenu } from 'shared/types/invoices';

interface Props {
  companyId: number;
  menuItems: DropDownMenu[];
  isVisibleMenu?: boolean;
  setOpenKeys: (key: number | null) => void;
  openKeys: number | null;
}

export const DropDownInvoice: React.FC<Props> = ({
  companyId,
  menuItems,
  isVisibleMenu = false,
  setOpenKeys,
  openKeys,
}) => {
  return (
    <Dropdown
      menu={{ items: menuItems }}
      open={openKeys === companyId}
      onOpenChange={(visible) => {
        if (visible) {
          setOpenKeys(companyId);
        } else if (openKeys === companyId) {
          setOpenKeys(null);
        }
      }}
      trigger={['click']}
    >
      <Icon component={DropDownIcon} />
    </Dropdown>
  );
};

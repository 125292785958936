import { IProfileData } from 'shared/types/profile';
import { IProfileRequest } from '../types/profileTypes';
import dayjs from 'dayjs';

export const transformCreateAccount = (
  data: IProfileData
): IProfileRequest => ({
  profile_type: data.profileType,
  cfdi: +data.cfdi,
  rfc: data.rfc,
  tax_regime: +data.taxRegime,
  name: data.name,
  first_surname: data.firstSurname,
  legal_entity_name: data.legalEntityName || '',
  second_last_name: data.secondLastName || '',
  birthdate: dayjs(data.birthDate as string).format('YYYY-MM-DD'),
  address: {
    and_street: data.address.andStreet,
    between_street: data.address.betweenStreet,
    colony_name: data.address.colonyName,
    exterior_number: data.address.exteriorNumber,
    federal_entity_name: data.address.federalEntityName,
    interior_number: data.address.interiorNumber,
    locality_name: data.address.localityName,
    municipality_name: data.address.municipalityName,
    postal_code: data.address.postalCode,
    road_name: data.address.roadName,
    road_type: data.address.roadType,
    state: data.address.state || '',
  },
});

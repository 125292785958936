import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';

import { ResetSuccessfullyForm } from '../../widgets/ResetPasswordSuccessfullyForm/types/resetSuccessfullyTypes';
import { CustomLabel } from 'shared/components/customLabel';
import { validatePassword, validatePasswordConfirm } from 'shared/libs/helpers';
import { Button } from 'shared/components/button';
import { Loading } from 'shared/components/loading';
import { CustomLink } from 'shared/components/customLink';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import {
  useSetNewPasswordMutation,
  useVerifyTokenMutation,
} from 'features/auth/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorMessage } from 'shared/components/errorMessage';
import { removeLocalStorage } from 'shared/libs/storageService/localStorage';
import { USER_EMAIL } from 'shared/constants';
import { Logo } from 'shared/assets/icons';
import { ErrorVerify } from 'shared/components/errorVerify';

type ErrorSetNewPassword = {
  data: {
    token?: string[];
    error?: string;
    new_password?: string[];
  };
};

const minLengthPassword = 8;

export const ResetPasswordSuccessfullyForm = () => {
  const [form] = Form.useForm<ResetSuccessfullyForm>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorRequest, setErrorRequest] = useState('');
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [fetchSetNewPassword, { isLoading }] = useSetNewPasswordMutation();

  const handleSubmit = async (value: ResetSuccessfullyForm) => {
    const tokenFromUrl = searchParams.get('token');
    try {
      await fetchSetNewPassword({
        new_password: value.newPassword,
        new_password_confirmation: value.confirmPassword,
        token: tokenFromUrl || '',
      }).unwrap();
      removeLocalStorage(USER_EMAIL);
      navigate(RoutePath['/reset-password/complete']);
    } catch (e: unknown) {
      const error = (e as ErrorSetNewPassword).data;

      if (error?.token) {
        return setIsInvalidToken(true);
      } else if (error?.error && error?.error === 'Invalid token.') {
        return setIsInvalidToken(true);
      } else if (error?.error && error?.error !== 'Invalid token.') {
        return setErrorRequest(error?.error);
      } else if (error?.new_password) {
        return setErrorRequest(error?.new_password[0]);
      }
      setErrorRequest('Something went wrong. Try again');
    }
  };

  const handleFormValuesChange = () => {
    setErrorRequest('');
    const values = form.getFieldsValue();
    const allFieldsFilled = Object.values(values).every((value) => value);
    const isMinLengthNewPassword =
      values.newPassword?.length >= minLengthPassword;
    const isMinLengthConfirmPassword =
      values.confirmPassword?.length >= minLengthPassword;
    setIsButtonDisabled(
      !allFieldsFilled || !isMinLengthNewPassword || !isMinLengthConfirmPassword
    );
  };

  return (
    <>
      {!isInvalidToken && (
        <>
          <div className="logo-register">
            <Logo />
          </div>
          <h2 className="header-text">Set Password</h2>
          <Form
            form={form}
            layout="vertical"
            style={{ maxWidth: '400px', width: '100%' }}
            initialValues={{}}
            onFinish={handleSubmit}
            onValuesChange={handleFormValuesChange}
          >
            <Form.Item
              label={<CustomLabel text="New Password" />}
              name="newPassword"
              rules={[
                { required: true, message: 'Please input your new password!' },
                ({ getFieldValue }) => ({
                  validator(_: any, value: string) {
                    return validatePassword(value, getFieldValue);
                  },
                }),
              ]}
              validateTrigger="onSubmit"
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              label={<CustomLabel text="Confirm New Password" />}
              name="confirmPassword"
              rules={[
                { required: true, message: 'Please input your password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return validatePasswordConfirm(value, getFieldValue, true);
                  },
                }),
              ]}
              validateTrigger="onSubmit"
            >
              <Input.Password size="large" />
            </Form.Item>

            <div className="buttons-wrapper mt-16">
              <Button
                type="submit"
                className="flex-row"
                disabled={isLoading || isButtonDisabled}
              >
                Set Password
                {isLoading && (
                  <Loading className="ml-12 spin-white" size="small" />
                )}
              </Button>
              {errorRequest && (
                <>
                  <ErrorMessage className="normal-16 mt-16">
                    {errorRequest}
                  </ErrorMessage>
                </>
              )}
            </div>
          </Form>
          <CustomLink className="mt-16" to={RoutePath['/login']}>
            Login
          </CustomLink>
        </>
      )}
      {isInvalidToken && <ErrorVerify text="The link isn't valid anymore" />}
    </>
  );
};

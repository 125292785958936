import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { profileApi, profileReducer } from 'features/profile/api';
import { authApi } from 'features/auth/api';
import { invoiceApi } from 'features/invoices/api';

const rootReducer = combineReducers({
  profileReducer,
  [authApi.reducerPath]: authApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        authApi.middleware,
        profileApi.middleware,
        invoiceApi.middleware
      ),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

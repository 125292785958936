export const statusesOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'in_processing',
    label: 'In Processing',
  },
  {
    value: 'processed',
    label: 'Processed',
  },
  {
    value: 'paused',
    label: 'Paused',
  },
  {
    value: 'submitting_error',
    label: 'Submitting Error',
  },
];

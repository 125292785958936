import {
  IProfile,
  IProfileResponse,
} from 'features/profile/types/profileTypes';

export const transformAccountResponse = (data: IProfileResponse): IProfile => ({
  address: {
    andStreet: data.address.and_street,
    betweenStreet: data.address.between_street,
    colonyName: data.address.colony_name,
    exteriorNumber: data.address.exterior_number,
    interiorNumber: data.address.interior_number,
    localityName: data.address.locality_name,
    municipalityName: data.address.municipality_name,
    roadName: data.address.road_name,
    roadType: data.address.road_type,
    postalCode: data.address.postal_code,
    federalEntityName: data.address.federal_entity_name,
    state: data.address.state || '',
  },
  cfdi: `${data.cfdi.code}: ${data.cfdi.description}`,
  rfc: data.rfc,
  taxRegime: `${data.tax_regime.code}: ${data.tax_regime.description}`,
  birthDate: data.birthdate,
  firstSurname: data.first_surname,
  name: data.name,
  legalEntityName: data.legal_entity_name,
  secondLastName: data.second_last_name,
  user: {
    email: data.user.email,
    id: data.user.id,
  },
  profileType: data.profile_type as IProfile['profileType'],
  cfdiId: data.cfdi.id,
  taxRegimeId: data.tax_regime.id,
  isOnboardingPassed: data.is_onboarding_passed || false,
});

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { useAppDispatch, useAppSelector } from 'app/providers/store';
import ProfileInfo from 'widgets/ProfileInfo/ui/ProfileInfo';
import { InvoiceInfo } from 'widgets/InvoiceInfo';
import {
  useLazyGetInvoiceByIdQuery,
  useSubmitInvoiceMutation,
} from 'features/invoices/api';
import { message, Spin } from 'antd';
import { IInvoiceById } from 'features/invoices/types/invoicesTypes';
import { useLazyGetProfileCodesQuery } from 'features/profile/api';
import { profileSlice } from 'features/profile/api/profileSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { IProfile } from 'features/profile/types/profileTypes';
import { transformAccountResponse } from 'shared/libs/transformers/profile';
import './styles/invoiceDetail.scss';
import { ModalError } from 'shared/components/modalError';
import { IModalConfirmContent, IModalErrorContent } from 'shared/types/shared';
import { ModalConfirm } from 'shared/components/modalConfirm';
import { downloadFile } from 'shared/libs/helpers';

const InvoiceDetail = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [profileData, setProfileData] = useState<IProfile | null>(null);

  const { codes } = useAppSelector((state) => state.profileReducer);
  const { setCodes } = profileSlice.actions;

  const [invoice, setInvoice] = useState<IInvoiceById | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloadFactura, setIsDownloadFactura] = useState(false);
  const [modalErrorContent, setModalErrorContent] =
    useState<IModalErrorContent>({
      title: '',
      content: null,
    });

  const [modalConfirmContent, setModalConfirmContent] =
    useState<IModalConfirmContent>({
      title: '',
      content: null,
      onCancel: () => null,
      onOk: () => null,
    });

  const dispatch = useAppDispatch();

  const [getInvoiceById] = useLazyGetInvoiceByIdQuery();
  const [fetchCodes] = useLazyGetProfileCodesQuery();
  const [fetchSubmitInvoice] = useSubmitInvoiceMutation();

  const navigateToSavedInvoices = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(RoutePath['/invoices/saved']);
    }
  };
  const fetchInvoiceById = async () => {
    if (!id) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const data = await getInvoiceById(id);
      if (data.error) {
        throw data.error;
      }
      if (!data.data) return;
      setProfileData(transformAccountResponse(data.data.profile));
      setInvoice(data.data);
    } catch (e) {
      console.error(e, 'Invoice details failed');
      message.error('Fetch invoices failed');
    } finally {
      setIsLoading(false);
    }
  };

  const getCodes = async () => {
    try {
      const data = await fetchCodes({});
      if (data.error) {
        throw data.error;
      }
      if (!data.data) return;
      dispatch(setCodes(data.data));
    } catch (e) {
      message.error('Fetch codes failed');
    }
  };

  const handleEditInvoice = (invoiceId: number) => {
    navigate(
      RoutePath['/invoice/edit/:id'].replace(':id', invoiceId.toString()),
      {
        state: { from: location.pathname },
      }
    );
  };

  const handlePreInvoiceSubmit = (invoiceId: number) => {
    if (invoice?.company_status === 'disabled') {
      setModalErrorContent({
        title: 'Error submit invoice',
        content: (
          <div>
            <p className="normal-16">{`Invoices for ${invoice.company_name} couldn't be submitted temporary. Try again later.`}</p>
          </div>
        ),
      });
      return;
    }
    if (invoice && !invoice?.match_config) {
      setModalErrorContent({
        title: 'Error submit invoice',
        content: (
          <div>
            <p className="normal-16">Selected invoice couldn't be sent.</p>
            <p className="normal-16">Fill empty invoice fields at first.</p>
          </div>
        ),
      });
      return;
    }
    setModalConfirmContent({
      title: '',
      content: (
        <div>
          <p>You are about to submit your invoice for processing. </p>
          <p>Would you like to continue?</p>
        </div>
      ),
      onCancel: handleCloseConfirmModal,
      onOk: () => handleSubmitInvoice(invoiceId),
    });
  };

  const handleSubmitInvoice = async (invoiceId: number) => {
    try {
      setIsLoading(true);

      const response = await fetchSubmitInvoice(invoiceId);
      if ('error' in response) {
        const error = response.error as FetchBaseQueryError;
        if (
          error.data &&
          typeof error.data === 'object' &&
          'error' in error.data
        ) {
          throw new Error(
            (error.data as { error: string }).error ||
              'Failed to submit invoice'
          );
        } else {
          throw new Error('Failed to submit invoice');
        }
      }
      await fetchInvoiceById();
      message.success('Invoice submitted successfully');
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'Failed to submit Invoice';
      message.error(errorMessage);
      console.log('ERROR', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoiceById();
  }, [id]);

  useEffect(() => {
    if (!codes.length) {
      getCodes();
    }
  }, [codes]);

  const handleCloseModalError = () => {
    setModalErrorContent({
      content: null,
      title: '',
    });
  };

  const handleCloseConfirmModal = () => {
    setModalConfirmContent({
      content: null,
      title: '',
      onCancel: () => null,
      onOk: () => null,
    });
  };

  const handlePrintInvoice = () => {
    if (!invoice || !invoice.factura_url) return;
    setIsDownloadFactura(true);
    const fileType = invoice.factura_url.split('.');
    downloadFile(
      invoice.factura_url,
      `factura.${fileType[fileType.length - 1]}`
    );
    setTimeout(() => {
      setIsDownloadFactura(false);
    }, 200);
  };

  return (
    <div className="invoice-detail">
      <ArrowLeftOutlined
        className="arrow-back"
        onClick={navigateToSavedInvoices}
      />
      {modalErrorContent.content && (
        <ModalError onOk={handleCloseModalError} {...modalErrorContent} />
      )}
      {modalConfirmContent.content && <ModalConfirm {...modalConfirmContent} />}

      {profileData?.name && (
        <ProfileInfo profile={profileData} title={'Personal Data'} />
      )}
      <Spin spinning={isLoading}>
        <InvoiceInfo
          codes={codes}
          invoice={invoice}
          handleEdit={handleEditInvoice}
          handleSubmit={handlePreInvoiceSubmit}
          handlePrintInvoice={handlePrintInvoice}
          isDownloadFactura={isDownloadFactura}
        />
      </Spin>
    </div>
  );
};
export default InvoiceDetail;

import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button } from 'shared/components/button';
import { CustomLabel } from 'shared/components/customLabel';
import { IChangePasswordForm } from '../types/changePasswordTypes';
import { validatePassword, validatePasswordConfirm } from 'shared/libs/helpers';
import { validateOldPassword } from 'shared/libs/helpers/validators/registerFormValidator/registerFormValidator';
import '../styles/changePassword.scss';
import { Loading } from 'shared/components/loading';
import { IChangePassword } from 'features/profile/types/profileTypes';
import { errorNewPassword, PasswordInfoText } from 'shared/constants';

interface IChangePasswordFormProps {
  handleBack: () => void;
  handleChangePassword: (data: IChangePassword) => Promise<void>;
  isLoading: boolean;
  error: string;
}

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = ({
  handleBack,
  handleChangePassword,
  isLoading,
  error,
}) => {
  const [form] = Form.useForm<IChangePasswordForm>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const minLengthPassword = 8;

  const handleSubmit = async (value: IChangePasswordForm) => {
    const dataSubmit: IChangePassword = {
      new_password: value.newPassword,
      old_password: value.oldPassword,
      password_confirmation: value.newPasswordConfirm,
    };
    await handleChangePassword(dataSubmit);
  };

  const handleFormValuesChange = () => {
    const values = form.getFieldsValue();
    const allFieldsFilled = Object.values(values).every((value) => value);
    const isMinLengthPassword = values.oldPassword?.length >= minLengthPassword;
    const isMinLengthConfirmPassword =
      values.newPasswordConfirm?.length >= minLengthPassword;

    setIsButtonDisabled(
      !allFieldsFilled || !isMinLengthPassword || !isMinLengthConfirmPassword
    );
  };

  useEffect(() => {
    if (error && error === errorNewPassword) {
      setIsPasswordError(true);
      form.setFields([
        {
          name: 'newPassword',
          errors: [errorNewPassword],
        },
      ]);
    } else if (error && error !== errorNewPassword) {
      form.setFields([
        {
          name: 'oldPassword',
          errors: [error],
        },
      ]);
    }
  }, [error]);

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ maxWidth: '680px', width: '100%' }}
      initialValues={{}}
      onFinish={handleSubmit}
      onValuesChange={handleFormValuesChange}
    >
      <Form.Item
        label={<CustomLabel text="Old Password" />}
        name="oldPassword"
        rules={[
          { required: true, message: 'Please input your old password!' },
          ({ getFieldValue }) => ({
            validator(_: any, value: string) {
              return validateOldPassword(value, getFieldValue, 'Old Password');
            },
          }),
        ]}
        validateTrigger="onSubmit"
      >
        <Input.Password size="large" />
      </Form.Item>
      <Form.Item
        label={<CustomLabel text="New Password" />}
        name="newPassword"
        rules={[
          { required: true, message: 'Please input your new password!' },
          ({ getFieldValue }) => ({
            validator(_: any, value: string) {
              return validatePassword(value, getFieldValue, 'New Password')
                .then(() => {
                  setIsPasswordError(false);
                })
                .catch((error) => {
                  setIsPasswordError(true);
                  return Promise.reject(error);
                });
            },
          }),
        ]}
        validateTrigger="onSubmit"
      >
        <Input.Password size="large" />
      </Form.Item>
      {!isPasswordError && (
        <span
          style={{ display: 'block', marginTop: '-20px' }}
          dangerouslySetInnerHTML={{
            __html: PasswordInfoText,
          }}
        />
      )}
      <Form.Item
        label={<CustomLabel text="Confirm New Password" />}
        name="newPasswordConfirm"
        rules={[
          {
            required: true,
            message: 'Please input your new confirm password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return validatePasswordConfirm(value, getFieldValue, true);
            },
          }),
        ]}
        validateTrigger="onSubmit"
        className="mt-20"
      >
        <Input.Password size="large" />
      </Form.Item>

      <div className="buttons-wrapper mt-16">
        <Button type="button" onClick={handleBack} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isButtonDisabled || isLoading}
          className="flex-row mb-24"
        >
          Change Password
          {isLoading && <Loading className="ml-12 spin-white" size="small" />}
        </Button>
      </div>
    </Form>
  );
};

export default ChangePasswordForm;

import React from 'react';
import '../styles/profile.scss';
import { IProfile } from 'features/profile/types/profileTypes';
import { ProfileKeysToLabel } from 'shared/types/profile';
import { Button } from 'shared/components/button';
import { CustomLink } from 'shared/components/customLink';
import { RoutePath } from 'shared/config/routerConfig/routerConfig';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface IProfileProps {
  profile: IProfile;
  handleEditProfile: () => void;
  handleBack: () => void;
}

export const Profile: React.FC<IProfileProps> = ({
  profile,
  handleEditProfile,
  handleBack,
}) => {
  const profileAddress = Object.keys(profile.address);
  return (
    <>
      <div className="profile-header">
        <ArrowLeftOutlined className="arrow-back" onClick={handleBack} />
        <Button onClick={handleEditProfile}>Edit</Button>
      </div>
      <div className="profile">
        <h2>Personal Data</h2>
        <div className="profile-field">
          <span className="label">Email</span>
          <div className="profile-field-item w-content">
            {profile.user.email}
          </div>
        </div>
        <div className="flex-block-profile">
          <div className="profile-field">
            <span className="label">Name</span>
            <div className="profile-field-item">{profile.name}</div>
          </div>
          <div className="profile-field">
            <span className="label">First Surname</span>
            <div className="profile-field-item">{profile.firstSurname}</div>
          </div>
        </div>
        <div className="flex-block-profile">
          {profile.secondLastName && (
            <div className="profile-field">
              <span className="label">Second Last Name</span>
              <div className="profile-field-item">{profile.secondLastName}</div>
            </div>
          )}

          <div className="profile-field">
            <span className="label">Date of Birth</span>
            <div
              className={`profile-field-item ${!profile.secondLastName && 'w-content'}`}
            >
              {profile.birthDate as string}
            </div>
          </div>
        </div>
        <div className="flex-block-profile">
          <div className="profile-field">
            <span className="label">RFC</span>
            <div className="profile-field-item ">{profile.rfc}</div>
          </div>
          <div className="profile-field">
            <span className="label">CFDI</span>
            <div className="profile-field-item ">{profile.cfdi}</div>
          </div>
        </div>
        <div className="flex-block-profile">
          <div className="profile-field">
            <span className="label">Tax Regime</span>
            <div className={`profile-field-item  `}>{profile.taxRegime}</div>
          </div>
          {profile.legalEntityName && (
            <div className="profile-field">
              <span className="label">Legal Entity Name</span>
              <div className={`profile-field-item  `}>
                {profile.legalEntityName}
              </div>
            </div>
          )}
          <div className="profile-field">
            <span className="label">Account Type</span>
            <div className={`profile-field-item  `}>{profile.profileType}</div>
          </div>
        </div>

        <div className="flex-block-profile">
          {profileAddress.map((item, idx) => {
            const key = item as keyof IProfile['address'];
            if (profile.address[key] as string) {
              return (
                <div
                  className="profile-field profile-field-address"
                  key={`${key}-${idx}`}
                >
                  <span className="label">
                    {
                      ProfileKeysToLabel[
                        item as keyof typeof ProfileKeysToLabel
                      ]
                    }
                  </span>
                  <div className="profile-field-item ">
                    {profile.address[key] as string}
                  </div>
                </div>
              );
            }
          })}
        </div>
        <CustomLink
          to={RoutePath['/change-password']}
          className="change-password-link"
        >
          Change Password
        </CustomLink>
      </div>
    </>
  );
};
